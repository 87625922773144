import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Bubble() {
    return (
        <>
            <Header />
            <div class="position-relative">
                <iframe width="100%" height="450px" marginheight="0" frameborder="0" border="0" scrolling="auto" src="https://games.cdn.famobi.com/html5games/b/bubble-tower-3d/v050/?fg_domain=play.famobi.com&amp;fg_aid=A1000-111&amp;fg_uid=1385d98a-b5f2-4339-bce7-b99a8dd2e8b0&amp;fg_pid=e37ab3ce-88cd-4438-9b9c-a37df5d33736&amp;fg_beat=415&amp;original_ref=https%3A%2F%2Fplay.famobi.com%2Fhtml5game%2F1385d98a-b5f2-4339-bce7-b99a8dd2e8b0%2FA1000-11"></iframe>
            </div>
            <div className="container-fluid mb-3">
                <Footer />
            </div>
        </>
    )
}
