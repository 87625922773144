import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Rummy() {
    return (
        <>
            <Header />
            <div class="position-relative">
                <iframe width="100%" height="450px" marginheight="0" frameborder="0" border="0" scrolling="auto" src="https://games.cdn.famobi.com/html5games/g/gin-rummy-classic/v190/?fg_domain=play.famobi.com&amp;fg_aid=A1000-100&amp;fg_uid=ab73af98-2432-4395-bae1-916323c30d93&amp;fg_pid=5a106c0b-28b5-48e2-ab01-ce747dda340f&amp;fg_beat=417&amp;original_ref=https%3A%2F%2Fhtml5games.com%2F"></iframe>
            </div>
            <div className="container-fluid mb-3">
                <Footer />
            </div>
        </>
    )
}
