import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Croudrun() {
    return (
        <>
            <Header />
            <div class="position-relative">
                <iframe width="100%" height="450px" marginheight="0" frameborder="0" border="0" scrolling="auto" src="https://games.cdn.famobi.com/html5games/c/crowd-run-3d/v040/?fg_domain=play.famobi.com&amp;fg_aid=A1000-100&amp;fg_uid=28ab613b-9f40-4ab7-8f09-aa19f32e3660&amp;fg_pid=5a106c0b-28b5-48e2-ab01-ce747dda340f&amp;fg_beat=401&amp;original_ref=https%3A%2F%2Fplay.famobi.com%2Fhtml5game%2F28ab613b-9f40-4ab7-8f09-aa19f32e3660%2FA1000-10"></iframe>
            </div>
            <div className="container-fluid mb-3">
                <Footer />
            </div>
        </>
    )
}
