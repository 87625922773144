import { ActionTypes } from "../contants/action-types";


export const setSession = (user) => {
    return {
        type: ActionTypes.SESSION,
        payload: user
    };
};


export const setUserLimit = (limit) => {
    return {
        type: ActionTypes.USER_LIMIT,
        payload: limit
    };
};