import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputTwoTone } from "@material-ui/icons";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import Util from "../libs/util";
import { setUserLimit } from "../appRedux/actions/Auth";

export default function MatkaPlay() {
  const [showDiv, setShowDiv] = useState(false);
  const toggleDataShow = () => {
    setShowDiv(!showDiv);
  };

  const { matkatype, id } = useParams();

  const navigate = useNavigate();
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const [addValue, setAddValue] = useState([]);
  const [form, setForm] = useState({
    digit: "0",
    point: "0",
    gameType: "",
  });

  const [saver, setSaver] = useState([]);
  const [saved, setSaved] = useState([]);
  const [total, setTotal] = useState(0);
  const [usedCoins, setUsedCoins] = useState("");
  const [addButtonHide, setAddButtonHide] = useState(true);
  const [max, setMax] = useState(0);

  const roundName = (val) => {
    if (val == "andar") return "HARUF";
    else if (val == "bhar" || val == "bahar") return "DHAI";
    else if (val == "jodi") return "JODI";
    else return "";
  };

  //const handleFocus = (event) => event.target.select();

  const onAddClickHandler = (evevt) => {
    if (matkatype == "andar" || matkatype == "bahar") {
      if (form.digit < 0 || form.digit > 9) {
        alert("value must be between 0-9");
        return false;
      }
    } else if (matkatype == "jodi") {
      if (form.digit.length != 2) {
        alert("value must be between 00-99");
        return false;
      }
    } else {
      navigate("/matka");
    }

    if (form.point <= 0) {
      alert("point should be greter than 0");
      return false;
    }

    // console.log(form.point);
    // console.log(max);

    if (parseInt(form.point) > parseInt(max)) {
      alert("point should not be greter than " + max);
      return false;
    }

    const temp = [...saver];
    let temor = 0;

    temp.map((datick) => {
      temor += parseInt(datick.point);
    });

    form.gameType = matkatype;

    setSaver([...saver, form]);

    temor += parseInt(form.point);

    setTotal(temor);
  };

  const fetchUserLimit = async () => {
    try {
      let response = await Util.formSubmit("/client/user/limit", "POST", {
        token: cookies.get("client"),
      });
      //let response = auth?.data;
      if (response.status === "TRUE") {
        setUsedCoins(response.usedLimit);
        // console.log(response.limit);
        dispatch(setUserLimit(response.limit));
      } else {
        dispatch(setUserLimit(0));
      }
    } catch (error) {
      dispatch(setUserLimit(0));
    }
  };

  const initMatka = async () => {
    let result = await Util.formSubmit("client/games/init", "POST", {
      token: cookies.get("client"),
      id: id,
    });

    //let result = response?.data;

    if (result.status == "TRUE") {
      setAddButtonHide(false);
      setMax(result.initDefault[matkatype].max);

      console.log(max);
    }
  };
  const onSaveClickHandler = async (event) => {
    if (window.confirm("are you sure to save")) {
      let response = await Util.formSubmit("/client/games/save", "POST", {
        saveMatka: saver,
        token: cookies.get("client"),
        id: id,
      });

      //let response = result?.data;

      if (response.status == "TRUE") {
        setSaver([]);
        setForm({
          digit: "0",
          point: "0",
          gameType: "",
        });
        fetchUserLimit();
      } else {
        alert(response.msg);
      }

      await getSaved();
    } else {
    }
  };

  const deleteRowManager = (index) => {
    const temp = [...saver];
    temp.splice(index, 1);

    let temor = 0;

    temp.map((datick) => {
      temor += parseInt(datick.point);
    });

    setTotal(temor);

    setSaver(temp);
  };

  const onInputChangeHandler = (event) => {
    const value = event.target.value;

    setForm({
      ...form,
      [event.target.name]: value,
    });
  };

  const getSaved = async () => {
    let result = await Util.formSubmit("/client/games/getbet", "POST", {
      token: cookies.get("client"),
      id: id,
    });

    //  let result = response?.data;

    // console.log(result);

    if (result.status == "TRUE") setSaved(result.msg);
  };

  useEffect(() => {
    (async () => {
      await initMatka();
      await getSaved();
    })();
  }, []);

  return (
    <>
      <Header />
      <div className="position-relative">
        <Link className="btn btn-primary w-100 backbutton" to="/matka">
          Back to Main Menu
        </Link>

        <div className="container-fluid">
          <div className="row mt-4">
            <table className="table table-bordered matka-table">
              <thead className="lgaai">
                <tr>
                  <th className="text-center"> {roundName(matkatype)}</th>
                  <th className="text-center">POINT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="number"
                      name="digit"
                      onChange={onInputChangeHandler}
                      value={form.digit}
                      defaultValue={0}
                      className="form-control text-center"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="point"
                      onChange={onInputChangeHandler}
                      value={form.point}
                      defaultValue={0}
                      className="form-control text-center"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <button
                      type="button"
                      onClick={onAddClickHandler}
                      className="addmatka"
                    >
                      Add
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              width="100%"
              cellpadding="2"
              cellspacing="2"
              className="table table-bordered old-match-bets mt-3 text-center"
              style={{ marginTop: "-15px" }}
            >
              <tbody>
                <tr className="text-white table-bordered lgaai">
                  <th
                    height="25"
                    align="center"
                    valign="middle"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Sr.
                  </th>
                  <th
                    align="right"
                    valign="middle"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Digit
                  </th>
                  <th
                    align="center"
                    valign="middle"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    {" "}
                    Point
                  </th>
                  <th
                    align="left"
                    valign="middle"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Game Type
                  </th>
                  <th
                    align="left"
                    valign="middle"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Delete
                  </th>
                </tr>

                {saver.map((item, index) => {
                  let kindex = index;
                  return (
                    <tr>
                      <td>{++kindex}</td>
                      <td> {item.digit}</td>
                      <td>{item.point}</td>
                      <td> {roundName(item.gameType)} </td>
                      <td>
                        {" "}
                        <button
                          type="button"
                          onClick={() => {
                            deleteRowManager(index);
                          }}
                          className="deletematka"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2}>Total</td>
                  <td>{total}</td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <button
                      type="button"
                      className="addmatka"
                      onClick={onSaveClickHandler}
                    >
                      DONE
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>

            {/*  */}

            <table
              width="100%"
              cellpadding="2"
              cellspacing="2"
              className="table table-bordered old-match-bets mt-3 text-center"
              style={{ marginTop: "-15px" }}
            >
              <tbody>
                <tr className="text-white table-bordered lgaai">
                  <th
                    height="25"
                    align="center"
                    valign="middle"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Sr.
                  </th>
                  <th
                    align="right"
                    valign="middle"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Digit
                  </th>
                  <th
                    align="center"
                    valign="middle"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    {" "}
                    Point
                  </th>
                  <th
                    align="left"
                    valign="middle"
                    bgcolor="#002D5B"
                    className="FontTextWhite10px"
                  >
                    Game Type
                  </th>
                </tr>

                {saved.map((item, index) => {
                  let kindex = index;
                  // console.log(item);
                  return (
                    <tr>
                      <td>{++kindex}</td>
                      <td>{item.bit}</td>
                      <td>{item.amount}</td>
                      <td>{roundName(item.bitType)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
