import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import Util from "../libs/util";
import Cookies from "universal-cookie";

export default function Ledger() {
  const [ledger, setLedger] = useState([]);
  const cookies = new Cookies();
  useEffect(() => {
    (async () => {
      let response = await Util.formSubmit("client/ledger/my", "POST", {
        token: cookies.get("client"),
      });
      if (response.status === "TRUE") {
        setLedger(response.data.data);
      }
    })();
  }, []);

  return (
    <>
      <Header />
      <div class="position-relative">
        <Link class="btn btn-primary backbutton w-100" to="/dashboard">
          Back to Main Menu
        </Link>
        <main class="warp table-responsive statement-tables">
          <table className="table-bordered text-center w-100 my-2">
            <tbody>
              <tr
                style={{
                  backgroundColor: "rgb(0, 45, 91)",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                <td colSpan={5}>MY LEDGER</td>
              </tr>
              <tr>
                <td
                  width="40%"
                  height={35}
                  align="center"
                  valign="middle"
                  bgcolor="#002D5B"
                  className="FontTextWhite10px"
                  style={{
                    verticalAlign: "middle",
                    textAlign: "center",
                    paddingRight: 5,
                    color: "white",
                  }}
                >
                  GAME
                </td>
                <td
                  width="15%"
                  align="center"
                  valign="middle"
                  bgcolor="#002D5B"
                  className="FontTextWhite10px"
                  style={{
                    verticalAlign: "middle",
                    textAlign: "center",
                    paddingRight: 5,
                    color: "white",
                  }}
                >
                  RESULT
                </td>
                <td
                  width="15%"
                  align="center"
                  valign="middle"
                  bgcolor="#002D5B"
                  className="FontTextWhite10px"
                  style={{
                    verticalAlign: "middle",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Won
                </td>
                <td
                  width="15%"
                  align="center"
                  valign="middle"
                  bgcolor="#002D5B"
                  className="FontTextWhite10px"
                  style={{
                    verticalAlign: "middle",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  LOST
                </td>
                <td
                  width="15%"
                  align="center"
                  valign="middle"
                  bgcolor="#002D5B"
                  className="FontTextWhite10px"
                  style={{
                    verticalAlign: "middle",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Balance
                </td>
              </tr>
              {ledger.map((row) => {
                return (
                  <tr>
                    <td>{row.PaymentType}</td>
                    <td>{row.winner}</td>
                    <td>{row.CrDr == "credit" ? Math.abs(row.Amount) : "0"}</td>
                    <td>{row.CrDr == "debit" ? Math.abs(row.Amount) : "0"}</td>
                    <td className="text-success">{row.balance}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      </div>
    </>
  );
}
