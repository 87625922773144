import React, { useState } from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Util from "../libs/util";
import Cookies from "universal-cookie";
import { ResultCrousel } from "./components/others/ResultCrousel";
import moment from "moment";

export const ShowMatkaBets = () => {
  const [startDate, setStartDate] = useState();
  const [matkas, setMatkas] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [bets, setBets] = useState([]);

  const cookies = new Cookies();

  const getGames = async (dates) => {
    setMatkas([]);
    setStartDate(dates);

    let response = await Util.formSubmit("/client/games/get-list", "POST", {
      token: cookies.get("client"),
      date: dates,
    });

    if (response.status == "TRUE") {
      setMatkas(response.msg);
    }
  };

  const getBets = async (event) => {
    let dataInput = event.target.value;

    dataInput = dataInput?.split("##");
    if (dataInput[0] != "Select Game") {
      let round_result = matkas.find((row) => row.sno == dataInput[0]);

      setResultData([round_result]);
    } else {
      setResultData([]);
      setBets([]);
    }

    let response = await Util.formSubmit("/client/games/get-bets", "POST", {
      token: cookies.get("client"),
      id: dataInput[0],
      ref: dataInput[1],
    });

    if (response.status == "TRUE") {
      setBets([...response.msg?.bets]);
    }
  };

  return (
    <>
      <Header />
      <div className="position-relative">
        <Link className="btn btn-primary w-100 backbutton" to="/dashboard">
          Back to Main Menu
        </Link>
        <div className="show-bits container-fluid">
          <div className="row">
            <div className="col-md-6">
              <label>Date</label>
              <DatePicker
                selected={startDate}
                className="form-control"
                onChange={(date) => getGames(date)}
              />
            </div>
            <div className="col-md-6">
              <label>Game</label>
              <select className="form-control" onChange={getBets}>
                <option value={null}>Select Game</option>
                {matkas.map((row) => {
                  return (
                    <option value={row.sno + "##" + row.ref_id}>
                      {row.matka_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <ResultCrousel result={resultData} />
          <div className="bet-show-container">
            {bets.length > 0 && (
              <table className="table table-striped">
                <tr>
                  <th>Sno</th>
                  <th>Bet T</th>
                  <th>Bet B</th>
                  <th>Num.</th>
                  <th>Point</th>
                  <th>Date</th>
                </tr>

                {bets.map((row, i) => {
                  let k = i;
                  return (
                    <tr>
                      <td>{++k}</td>
                      <td>{row.bitType}</td>
                      <td>{row.gameType}</td>
                      <td>{row.bit}</td>
                      <td>{row.amount}</td>
                      <td>
                        {moment(row.datentime).format("DD-MM-YYYY hh:mm A")}
                      </td>
                    </tr>
                  );
                })}
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
