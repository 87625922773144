import { ActionTypes } from "../contants/action-types";

const initialState1 = {
    user: []
};
const initialState2 = 0;

export const sessionReducers = (state = initialState1, { type, payload }) => {
    switch (type) {
        case ActionTypes.SESSION:
            return payload;
        default:
            return state;
    }
}

export const userLimitReducers = (state = initialState2, { type, payload }) => {
    switch (type) {
        case ActionTypes.USER_LIMIT:
            return payload;
        default:
            return state;
    }
}