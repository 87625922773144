import React from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";

export const MatkaResults = () => {
  return (
    <>
      <Header />
      <div className="position-relative">
        <Link className="btn btn-primary w-100 backbutton" to="/dashboard">
          Back to Main Menu
        </Link>

        <div className="container-fluid pd2">
          <div className="row mt-4">
            <div className="col-lg-6">
              <select className="form-control text-center">
                <option value={""}>Select Market</option>
              </select>
            </div>
            <div className="col-lg-6">
              <Calendar />
            </div>
          </div>
        </div>
        <br />
        <Link className="btn btn-primary backbutton w-100" to="/dashboard">
          Back to Main Menu
        </Link>
      </div>
    </>
  );
};
