import React, { useState } from "react";
import Header from "./components/Header";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

export default function Rules() {
  const [activeTab, setActiveTab] = useState("English");

  const cookies = new Cookies();
  const navigate = useNavigate();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = async () => {
    cookies.remove("client", { path: "/" });
    navigate("/");
  };

  return (
    <>
      <div class="position-relative">
        <div class="d-flex m-auto" style={{ width: "300px" }}>
          {/* <button
            type="button"
            onClick={() => handleTabClick("Hindi")}
            class="w-100 tab-button btn btn-primary"
            style={{ backgroundColor: "rgb(132, 179, 50)" }}
          >
            Hindi
          </button> */}
        </div>
        <div class="mt-4 container">
          <div>
            <ol class="rules-list">
              <li>लोगिन करने के बाद अपना पासवर्ड बदल लें।</li>
              <li>
                ये प्लेटफार्म यूजर की सहूलियत के लिए है, एजेंट द्वारा डायरेक्ट
                किये गए सौदे रद्द मान कर हटा दिए जाएंगे।
              </li>
              <li>
                सौदे हटाने पर किसी भी प्रकार का बाद विवाद स्वीकार्य नहीं होगा |
              </li>
              <li>किस भी प्रकार की दिक्कत आने पर अपने एजेंट से मदद मांगे। </li>
              <li>
                सभी सरत स्वीकार होने पर <strong>स्वीकार है</strong> पर क्लिक
                करें , अन्यथा <strong>लॉगआउट/Logout</strong> पर क्लिक करके
                एप्लीकेशन बंद करदें।
              </li>
            </ol>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <Link
              to="/dashboard"
              class="d-flex m-auto btn btn-primary backbutton"
            >
              शर्तें स्वीकार हैं।
            </Link>
            <button
              class="d-flex m-auto btn btn-danger backbutton"
              onClick={handleLogout}
            >
              लॉगआउट / Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
