import React, { useState } from "react";
import Header from "./components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import Util from "../libs/util";


export default function Profile() {

    const [rateDifferent, setRateDifferent] = useState("");

    const cookies = new Cookies();
    const navigate = useNavigate();
    const user = useSelector((state) => state.session);

    const handleRateDifferent = (values) => {
        console.log(values);
        setRateDifferent(values);
    }

    const updateRateDifferent = async () => {

        let response = await Util.formSubmit("client/user/setbitdifferent", "POST", { "token": cookies.get("client"), "rate": rateDifferent });
        if (response.status === "TRUE") {
            cookies.remove("client", { path: "/" });
            navigate('/')
        }

    }

    

    return (
        <>
            <Header />
            <div class="position-relative">
                <Link class="btn btn-primary backbutton w-100" to="/dashboard">Back to Main Menu</Link>
                <div class="container-fluid">
                    <div class="row">
                        <div class="p-0 text-center col-sm-12">
                            <h5 class="bg-theme-light profile-title">Rate Information</h5>
                        </div>
                        <div class="col-sm-4 col-4">Rate Diff.</div>
                        <div class="col-sm-4 col-4"><select class="w-100" onChange={(e) => handleRateDifferent(e.target.value)}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select></div>
                        <div class="col-sm-4 col-4"><button type="button" class="btn btn-primary btn-sm" onClick={() => updateRateDifferent()}>Update</button></div>
                    </div>
                    <div class="row">
                        <div class="p-0 text-center mt-3 col-sm-12">
                            <h5 class="bg-theme-dark profile-title">Personal Information</h5>
                        </div>
                        <div class="col-sm-6 col-6">Client Code:</div>
                        <div class="col-sm-6 col-6">{user.user?.userid}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-6">Client Name:</div>
                        <div class="col-sm-6 col-6">{user.user?.username}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-6">Date Of Joining:</div>
                        <div class="col-sm-6 col-6">---</div>
                    </div>
                </div>
            </div>

        </>
    )
}
