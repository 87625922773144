import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import ls from "local-storage";
import Util from "../libs/util";
import { Row } from "react-bootstrap";

export default function Inplay() {
  const [match, setMatch] = useState([]);

  useEffect(() => {
    fetchMatch();
  }, []);

  const [activeTab, setActiveTab] = useState("All"); // State to keep track of active tab
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const fetchMatch = async () => {
    const cookies = new Cookies();
    let response = await Util.formSubmit("client/cricket/list", "POST", {
      token: cookies.get("client"),
    });
    if (response.status === "TRUE") {
      setMatch(response.data);
      let obj = ls.get("allmatches") == null ? [] : ls.get("allmatches");
      let obj2 = [];
      for (let iterator of response.data) {
        obj2.push(iterator.sno);
        // console.log(ls.get(iterator.sno));
        if (ls.get(iterator.sno) == null) {
          ls.set(iterator.sno, false);
          obj.push(iterator.sno);
        }
      }
      let difference = obj.filter((x) => !obj2.includes(x));
      // console.log(difference);

      for (let ob of difference) {
        removeItemAll(obj, ob);
        //                console.log(ob);
        ls.remove(ob);
      }
      // console.log(obj);
      ls.set("allmatches", obj);
    }
  };
  function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  return (
    <>
      <Header />
      <div class="position-relative">
        <main class="warp mt-1">
          <Link class="btn btn-primary backbutton w-100" to="/dashboard">
            Back to Main Menu
          </Link>
          <div>
            <ul className="games-types">
              <li
                className={activeTab === "All" ? "active" : ""}
                onClick={() => handleTabClick("All")}
              >
                <img src="/assets/images/all.png" alt="All" />
                All
              </li>
              <li
                className={activeTab === "Cricket" ? "active" : ""}
                onClick={() => handleTabClick("Cricket")}
              >
                <img src="/assets/images/cricket.png" alt="Cricket" />
                Cricket
              </li>
              <li
                className={activeTab === "Football" ? "active" : ""}
                onClick={() => handleTabClick("Football")}
              >
                <img src="/assets/images/soccer.png" alt="Football" />
                Football
              </li>
              <li
                className={activeTab === "Tennis" ? "active" : ""}
                onClick={() => handleTabClick("Tennis")}
              >
                <img src="/assets/images/tennis.png" alt="Tennis" />
                Tennis
              </li>
            </ul>

            {activeTab === "All" && (
              <div>
                {match.map((row) => {
                  return (
                    <div class="old-matches-list live-match">
                      <div class="TeamName">
                        <Link to={"/play-match/"+row.sno}>
                          {row.MatchName}
                          <span class="d-inline-flex align-items-center float-left mx-2">
                            <div class="blink"></div>
                          </span>
                        </Link>
                      </div>
                      <div class="old-match-details">
                        <Link to={"/play-match/"+row.sno}>
                          <table
                            width="100%"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                          >
                            <tbody>
                              <tr>
                                <td width="1%">&nbsp;</td>
                                <td class="GameList">
                                  <table
                                    width="99%"
                                    border="0"
                                    cellspacing="0"
                                    cellpadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td class="GameList" align="center">
                                          {row.MatchDateTime}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="GameList" align="center">
                                          MATCH BETS : <span>0</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="GameList" align="center">
                                          Session Bets : <span>0</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width="1%">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {activeTab === "Cricket" && (
              <div>
                {match.map((row) => {
                  return (
                    <div class="old-matches-list live-match">
                      <div class="TeamName">
                        <Link to={"/play-match/"+row.sno}>
                          {row.MatchName}
                          <span class="d-inline-flex align-items-center float-left mx-2">
                            <div class="blink"></div>
                          </span>
                        </Link>
                      </div>
                      <div class="old-match-details">
                        <Link to={"/play-match/"+row.sno}>
                          <table
                            width="100%"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                          >
                            <tbody>
                              <tr>
                                <td width="1%">&nbsp;</td>
                                <td class="GameList">
                                  <table
                                    width="99%"
                                    border="0"
                                    cellspacing="0"
                                    cellpadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td class="GameList" align="center">
                                          {row.MatchDateTime}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="GameList" align="center">
                                          MATCH BETS : <span>0</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="GameList" align="center">
                                          Session Bets : <span>0</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width="1%">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {activeTab === "Football" && <div></div>}
            {activeTab === "Tennis" && <div></div>}
          </div>
          <Link class="btn btn-primary backbutton w-100" to="/dashboard">
            Back to Main Menu
          </Link>
        </main>
      </div>
    </>
  );
}
