import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Footer from "./components/Footer";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { setSession } from "../appRedux/actions/Auth";
import Util from "../libs/util";

function LoginPage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const location = useLocation();

  const [cvalue, setValue] = useState("C");

  const [credentials, setCredentials] = useState({
    userid: undefined,
    password: undefined,
    authToken: props.token,
  });

  const [hasError, setHasError] = useState({
    userid: undefined,
    password: undefined,
    authToken: props.token,
  });

  const [isLogin, setIsLogin] = useState(false);

  const handleChange = async (e) => {
    if (e.target.name == "userid")
      setCredentials((prev) => ({
        ...prev,
        [e.target.name]: `c${e.target.value}`,
      }));
    else
      setCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    handleAuth();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    console.log(e);
    let response = await Util.formSubmit("client/login", "POST", credentials);

    if (response.status === "TRUE") {
      cookies.set("client", response.token, { path: "/" });
      dispatch(setSession(response.userinfo));
      navigate("/rules");
    } else alert(response.msg);
  };
  const handleAuth = async () => {
    let auth = await Util.formSubmit("client/user", "POST", {
      token: cookies.get("client"),
    });
    if (auth.status == "TRUE") {
      // window.location = '/welcome'
      setIsLogin(true);
    }
  };

  return isLogin ? (
    <Navigate to="/dashboard" state={{ from: location }} replace />
  ) : (
    <>
      <div className="login-page">
        <div className="d-flex align-items-center" style={{ height: "100vh" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <center>
                  <img
                    src="assets/images/logo.jpg"
                    className="logo"
                    alt="logo"
                  />
                </center>
              </div>
              <div className="m-auto position-relative col-lg-4 col-md-6 col-sm-7 col-12">
                <span className="cstyle">C</span>
                <TextField
                  type="text"
                  name="userid"
                  className="login-input-field pdleft-4 error"
                  fullWidth
                  label="Client Code *"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />
                <TextField
                  type="password"
                  name="password"
                  className="login-input-field"
                  fullWidth
                  label="Password *"
                  variant="outlined"
                  onChange={handleChange}
                />

                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    fullWidth
                    className="login-button text-white"
                    type="button"
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                </Stack>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LoginPage;
