import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Util from "../../../libs/util";
import Cookies from "universal-cookie";
import { setUserLimit } from "../../../appRedux/actions/Auth";

export const Manual = ({ gameRef, gameId }) => {
  const n = 10;
  const userLimit = useSelector((state) => state.userLimit);
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const [pointsA, setPointsA] = useState(0);
  const [jodies, setJodies] = useState([]);
  const [totals, setTotals] = useState([]);

  const manualJodi = (event, ref, refs) => {
    let temp_jodi = [...jodies];
    if (event.target.value.length == 2) {
      const isObjectPresent = temp_jodi.findIndex((o) => o.ref === ref);

      if (isObjectPresent >= 0) {
        temp_jodi[isObjectPresent].jodi =
          event.target.value == "" ? 0 : event.target.value;
      } else {
        temp_jodi.push({
          jodi: event.target.value,
          ref: ref,
          points: 0,
          type: "jodi",
        });
      }

      setJodies(temp_jodi);
      setTotal(temp_jodi, refs);
    } else {
      const isObjectPresent = temp_jodi.findIndex((o) => o.ref === ref);
      if (isObjectPresent >= 0) temp_jodi.splice(isObjectPresent, 1);
      setJodies(temp_jodi);
    }

    setTotal(temp_jodi, refs);

    console.log(temp_jodi);
  };

  const setTotal = (jodiGr, ref) => {
    let results = jodiGr.filter((row) => row.ref.includes(ref));
    let total = results.reduce((acc, o) => acc + parseInt(o.points), 0);

    const isObjectPresent = totals.findIndex((o) => o.ref === ref);

    let temp_totals = [...totals];

    if (isObjectPresent >= 0) {
      temp_totals[isObjectPresent].total = total;
    } else {
      temp_totals.push({ total: total, ref: ref });
    }

    setTotals(temp_totals);

    const sumPoints = temp_totals.reduce(
      (acc, o) => acc + parseInt(o.total),
      0
    );

    setPointsA(sumPoints);
    // console.log(temp_totals);
  };

  const setPoints = (events, ref) => {
    let points = events.target.value;
    if (events.target.value < 5 || isNaN(events.target.value)) points = 0;

    let temp_jodi = [...jodies];

    temp_jodi.map((row, i) => {
      if (row.ref.includes(ref)) {
        temp_jodi[i].points = points;
      }
    });

    // console.log(temp_jodi);

    setJodies(temp_jodi);

    setTotal(temp_jodi, ref);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (userLimit - pointsA <= 0) {
      alert("Limit Exceed, cannot save !!!");
      return false;
    }

    let errorJodi = jodies.filter((row) => parseInt(row.points) > 5000);

    console.log(errorJodi);
    if (errorJodi.length > 0) {
      alert("single bet more than 5000 not allowed !!!");
      return false;
    }

    if (jodies.length > 0) {
      let response = await Util.formSubmit("client/games/save", "POST", {
        svm: jodies,
        gameId: gameId,
        refs: gameRef,
        boardType: "MANUAL",
        token: cookies.get("client"),
      });

      if (response.status == "TRUE") {
        await isUserLimit();
        setJodies([]);
        setPointsA(0);

        alert(response.msg);
      } else {
        alert(response.msg);
      }
    }
  };

  const isUserLimit = async () => {
    let userinfo = await Util.formSubmit("client/user/limit", "POST", {
      token: cookies.get("client"),
    });
    if (userinfo.status === "TRUE") {
      // this.props.setUserLimit(userinfo.limit)
      dispatch(setUserLimit(userinfo.limit));
      // setExposure(userinfo.exposure);
    }
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  useEffect(() => {
    return () => {};
  }, [totals]);

  return (
    <>
      <div className="pointer">
        <div>
          <p className="header">Points Remaining</p>
          <p className="point-value">{userLimit - pointsA}</p>
        </div>
        <div>
          <p className="header">Points Added</p>
          <p className="point-value">{pointsA}</p>
        </div>
      </div>

      <div className="manual">
        <div className="header">
          <div className="inner">
            <h2>Jodi</h2>
          </div>
          <div className="inner">
            <h2>Point</h2>
          </div>
          <div className="inner">
            <h2>Total</h2>
          </div>
        </div>

        {[...Array(n)].map((e, i) => {
          let objTotal = totals?.find((o) => o.ref === i);

          return (
            <div className="main">
              <div key={i} className="inner">
                <div className="input-container">
                  <input
                    type="number"
                    onChange={(event) => {
                      manualJodi(event, "A" + i, i);
                    }}
                    maxLength="2"
                    onInput={maxLengthCheck}
                  />
                </div>
                <div className="input-container">
                  <input
                    type="number"
                    onChange={(event) => {
                      manualJodi(event, "B" + i, i);
                    }}
                    maxLength="2"
                    onInput={maxLengthCheck}
                  />
                </div>
                <div className="input-container">
                  <input
                    type="number"
                    onChange={(event) => {
                      manualJodi(event, "C" + i, i);
                    }}
                    maxLength="2"
                    onInput={maxLengthCheck}
                  />
                </div>
                <div className="input-container">
                  <input
                    type="number"
                    onChange={(event) => {
                      manualJodi(event, "D" + i, i);
                    }}
                    maxLength="2"
                    onInput={maxLengthCheck}
                  />
                </div>
                <div className="input-container">
                  <input
                    type="number"
                    onChange={(event) => {
                      manualJodi(event, "E" + i, i);
                    }}
                    maxLength="2"
                    onInput={maxLengthCheck}
                  />
                </div>
              </div>
              <div className="inner">
                <div className="input-container">
                  <input
                    onChange={(event) => {
                      setPoints(event, i);
                    }}
                  />
                </div>
              </div>
              <div className="inner">
                <div className="input-container">
                  <input value={objTotal?.total} />
                </div>
              </div>
            </div>
          );
        })}
        <div className="footer">
          <button className="btn btn-primary" onClick={onSubmit}>
            Play
          </button>
        </div>
      </div>
    </>
  );
};
