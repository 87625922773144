import React from "react";
import Clock from "react-live-clock";
export const IntroWatch = () => {
  return (
    <>
      <div className="introwatch">
        <p>💥 भरोसे का एक ही नाम 💥</p>
        <p>🙏🏼 1EX Matka 🙏🏼</p>
        <div className="watch">
          <p>
            <Clock format={"DD-MM-YYYY"} timezone={"Asia/Kolkata"} />
          </p>
          <Clock
            format={"hh:mm:ss A"}
            ticking={true}
            timezone={"Asia/Kolkata"}
          />
        </div>
      </div>
    </>
  );
};
