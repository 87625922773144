import React, { useEffect, useState } from 'react';
import Header from "./components/Header";
import { Link } from "react-router-dom";
import Util from '../libs/util';
import Cookies from 'universal-cookie';


export default function Statement() {
const [activeTab, setActiveTab] = useState('CRICKET');
const [complete, setComplete] = useState([]);

const cookies = new Cookies();

const handleTabClick = (tab) => {
setActiveTab(tab);
};

useEffect(() => {
  (async () => {
    let response = await Util.formSubmit("client/cricket/complete-list", "POST", {
      token: cookies.get("client"),
    });
    if (response.status === "TRUE") {
      setComplete(response.data);
    }
  })();
}, []);







return (
<>
  <Header />
  <div className="position-relative">
    <Link className="btn btn-primary backbutton w-100" to="/statement">Back to Main Menu</Link>
   
    <div className="container-fluid p-0">
      <main className="warp">
        {activeTab === 'CRICKET' && (
        <div>
          <main className="warp">
            <div>
              { complete.map(row=>{
                return <div className="old-matches-list ">
                <div className="TeamName">
                  <Link to="/view-statement">{row.MatchName} ({row.MatchType})</Link>
                </div>
                <div className="old-match-details">
                  <Link to={"/view-statement/"+row.sno}>
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td width="1%">&nbsp;</td>
                          <td className="GameList" style={{ verticalAlign: 'top' }}>
                            <table width="99%" border="0" cellSpacing="0" cellPadding="0">
                              <tbody>
                                <tr>
                                  <td className="GameList" align="center">{row.MatchDateTime}</td>
                                </tr>
                                <tr>
                                  <td className="GameList" align="center">MATCH BETS : <span>{row.mbets}</span></td>
                                </tr>
                                <tr>
                                  <td className="GameList" align="center">Session Bets : <span>{row.sbets}</span></td>
                                </tr>
                                <tr>
                                  <td className={row.plusMinus>0?"GameList text-success":"GameList text-danger"} align="center">{row.plusMinus>0?"Won":"Loss"} coins : <span>{row.plusMinus}</span></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td width="1%">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </Link>
                </div>
              </div> })}

           
              
            </div>
          </main>
        </div>
        )}
        {activeTab === 'CASINO' && (
        <div>
          <h5 className='mt-3 text-center'>CASINO</h5>
        </div>
        )}
      </main>
    </div>
  </div>

</>
)
}
