import React from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";

export default function Casino() {
    return (
        <>
            <Header />
            <div class="position-relative"><Link class="btn btn-primary w-100 backbutton" to="/dashboard">Back to Main Menu</Link>
                <div class="container">
                    <div class="align-items-end mt-2  row">
                        <div class="text-center mt-2 col-sm-4 col-6"><Link class="text-dark" to="/twenty-twenty-teenpatti"><img height="100" src="https://victoryexch.com//casino/images/banner/poker/3-PATTI-ONE-DAY.png" alt="" class="w-100 card_3d" />
                        <b style={{ color: 'rgb(210, 164, 60)' }}>TeenPatti T-20 </b>
                        </Link>
                        </div>
                        <div class="text-center mt-2  col-sm-4 col-6">
                            <Link class="text-dark" to="/dragon-tiger">
                                <img height="100" src="https://victoryexch.com//casino/images/banner/poker/dragon-tiger.png" alt="" class="w-100 card_3d" />
                                <b style={{ color: 'rgb(210, 164, 60)' }}>Dragon Tiger</b>
                                </Link>
                        </div>
                        <div class="text-center  col-sm-4 col-6"><Link class="text-dark mt-2" to="/lucky-7">
                            <img height="100" src="https://victoryexch.com//casino/images/banner/poker/7-UP-7-DOWN.png" alt="" class="w-100 card_3d" />
                            <b style={{ color: 'rgb(210, 164, 60)' }}>Lucky 7</b>
                            </Link>
                        </div>
                        <div class="text-center mt-2  col-sm-4 col-6"><Link class="text-dark" to="/aaa">
                            <img height="100" src="/assets/images/aaa.png" alt="" class="w-100 card_3d" />
                            <b style={{ color: 'rgb(210, 164, 60)' }}>AAA</b>
                        </Link>
                        </div>
                    </div>
                    <img height="100" src="https://victoryexch.com/images/slider/slider4.jpg" alt="" class="w-100 card_3d mt-4" style={{ marginBottom: '60px' }} />
                </div>
                <Link class="btn btn-primary w-100 backbutton" to="/">Back to Main Menu</Link>
            </div>

        </>
    )
}
