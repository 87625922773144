import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
// import Modal from '@mui/material/Modal';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "universal-cookie";
import Util from "../libs/util";
import ls from "local-storage";
import sha256 from "sha256";
import { useDispatch } from "react-redux";
import { setUserLimit } from "../appRedux/actions/Auth";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PlayMatch() {
  const cookies = new Cookies();
  const { id } = useParams();
  const navigate = useNavigate();
  var matchTimeOut;
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [BetModalopen, setBetModalOpen] = React.useState(false);
  const [oddsData, setOddsData] = useState([]);
  const [fancyData, setFancyData] = useState([]);
  const [score, setScore] = useState({});
  const [scoreBall6, setScoreBall6] = useState([]);
  const [system, setSystem] = useState({});
  const [oddsPositionAmt, setOddsPositionAmt] = useState([]);
  const [fancyPlusMinus, setFancyPlusMinus] = useState("");
  const [betbuttonDisable, setBetButtonDisable] = useState(false);
  const [progress, setProgress] = useState(0);
  const handleBetModalOpen = () => setBetModalOpen(true);
  const handleBetModalClose = () => setBetModalOpen(false);
  const [exposure, setExposure] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [scoreText, setScoreText] = useState("Full Score");
  const [iframeHeight, setIframeHeight] = useState(135);
  const [showDiv, setShowDiv] = useState(false);
  const [oddsBetsList, setOddsBetsList] = useState([]);
  const [fancyBetsList, setFancyBetsList] = useState([]);
  const [tvLink, setTvLink] = useState("");
  const [downloadTimer, setDownloadTimer] = useState(null);
  const [downloadTimeOut, setDownloadTimeOut] = useState(null);
  const [timemer, setTimemer] = useState("");
  const [loader, setLoader] = React.useState(false);

  const [pannelData, setPannelData] = useState({
    title: "",
    betType: "",
    bets: "",
    teamId: "",
    type: "",
    sessionId: "",
    run: "",
  });

  const handleToggle = () => {
    if (scoreText === "Full Score") {
      setScoreText("Half Score");
      setIframeHeight(250);
    } else {
      setScoreText("Full Score");
      setIframeHeight(135);
    }
  };
  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };

  // amount
  const [amount, setAmount] = useState("");
  const handleButtonClick = (value) => {
    setAmount(value);
  };
  const handleInputChange = (event) => {
    setAmount(event.target.value);
  };

  const fetchMatch = async () => {
    const cookies = new Cookies();
    let response = await Util.formSubmit("client/cricket/list", "POST", {
      token: cookies.get("client"),
    });
    if (response.status === "TRUE") {
      let arr = response.data;
      for (const iterator of arr) {
        if (
          parseInt(iterator.sno) === parseInt(id) &&
          iterator.DeclareStatus.toString().toUpperCase() === "RUNNING"
        ) {
          initMatch();
          break;
        }
      }
    }
  };

  const initMatch = async () => {
    if (ls.get(id) == null) {
      navigate("/cricket");
    }

    let shap = `e7550b4bc9cbc36cb0869491927caf52bda5a8440fdf69b7d9a54f38eba942b546fffed8c51da8eccb8246132742a571a109b21e2e1f9e68f1153699b8c334e2${id}${cookies.get(
      "client"
    )}7877dec5be575e6a54be47e87d7e20121bebb7931b4601a1442318f222e01b390ab7e37fc3d68071a34440c7da8b503cdcd76ef59e1ee7e585917cbe1246d56a`;
    let sha = sha256(shap);

    if (ls.get(id) !== sha) {
      let init = await Util.formSubmit("client/cricket/init", "POST", {
        token: cookies.get("client"),
        matchId: id,
        matchAuthToken: sha,
      });
      if (init.status === "TRUE") {
        ls.set(id, init.token);
      }
    }
  };

  const handleMatches = async () => {
    let response = await Util.formSubmit("data/cricket", "POST", {
      token: cookies.get("client"),
      matchId: id,
    });

    if (response.status === "TRUE") {
      setOddsData(response.data.ODDS);
      setFancyData(response.data.FANCY);
      setScore(response.data.SCORE);
      setScoreBall6(response.data.SCORE.balls6new);
      setSystem(response.data.SYSTEM);
    }
  };

  const oddsPositionResponse = async () => {
    let oddsPositionResponse = await Util.formSubmit(
      "client/cricket/odds/position",
      "POST",
      {
        token: cookies.get("client"),
        matchId: id,
      }
    );
    if (oddsPositionResponse.status === "TRUE") {
      setOddsPositionAmt(oddsPositionResponse.position);
    }
  };

  const isUserLimit = async () => {
    let userinfo = await Util.formSubmit("client/user/limit", "POST", {
      token: cookies.get("client"),
    });
    if (userinfo.status === "TRUE") {
      // this.props.setUserLimit(userinfo.limit)
      dispatch(setUserLimit(userinfo.limit));
      setExposure(userinfo.exposure);
    }
  };

  const handleFancyPlusMinus = async () => {
    let fancyPlusMin = await Util.formSubmit(
      "client/cricket/fancy/plusminus",
      "POST",
      {
        token: cookies.get("client"),
        matchId: id,
      }
    );
    if (fancyPlusMin.status == "TRUE") {
      setFancyPlusMinus(fancyPlusMin.plusminus);
    }
  };
  const handleOddsBetsList = async () => {
    let response = await Util.formSubmit("client/cricket/odds/list", "POST", {
      token: cookies.get("client"),
      matchId: id,
    });
    if (response.status === "TRUE") {
      setOddsBetsList(response.data);
    }
  };

  const handleFancyBetsList = async () => {
    let response = await Util.formSubmit("client/cricket/fancy/list", "POST", {
      token: cookies.get("client"),
      matchId: id,
    });
    if (response.status === "TRUE") {
      setFancyBetsList(response.data);
    }
  };
  const handleBetsSubmit = async (
    name,
    bettype,
    betrate,
    type,
    teamId,
    sessionId,
    run
  ) => {
    setPannelData({
      title: "",
      betType: "",
      bets: "",
      teamId: "",
      type: "",
      sessionId: "",
      run: "",
    });
    clearInterval(downloadTimer);
    clearTimeout(downloadTimeOut);

    if (betrate === "0.00") {
      return;
    }
    setAmount("");

    setPannelData({
      title: name,
      betType: bettype,
      bets: betrate,
      type: type,
      teamId: teamId,
      sessionId: sessionId,
      run: run,
    });

    setBetModalOpen(true);

    var timer = 8;
    var differ = 1;
    setTimemer(timer.toString());
    setDownloadTimer(
      setInterval(() => {
        if (timer - differ <= 0) clearInterval(downloadTimer);
        setTimemer((timer - differ).toString());
        // console.log((timer - differ));
        differ++;
      }, 1000)
    );

    setDownloadTimeOut(
      setTimeout(() => {
        setBetModalOpen(false);
        clearInterval(downloadTimer);
        clearTimeout(downloadTimeOut);
      }, timer * 1000)
    );
  };
  const oddsResponse = async () => {
    let oddsResponse = await Util.formSubmit(
      "client/cricket/odds/submit",
      "POST",
      {
        token: cookies.get("client"),
        matchId: id,
        amount: amount,
        betType: pannelData.betType,
        bets: pannelData.bets,
        teamId: pannelData.teamId,
      }
    );

    if (oddsResponse.status === "TRUE") {
      setLoader(false);
      // alert("done");
      var tempProgress = progress;
      var progressInterval = setInterval(() => {
        tempProgress += 20;
        setProgress(tempProgress);

        setProgress(tempProgress);

        handleBetModalClose();
        setBetButtonDisable(false);
        if (tempProgress > 100) clearInterval(progressInterval);
        setProgress(0);
      }, 5);
      setTimeout(() => {
        setLoader(false);
        toast.info("BET SUCCESSFULLY DONE", {
          autoClose: 2000,
          icon: false,
          closeButton: false,
          position: "top-center",
          hideProgressBar: true,
        });
      }, 300);
      handleOddsBetsList();
    } else {
      //  setModalShow(false);
      //  setBetButtonDisable(false);

      var tempProgress = progress;
      var progressInterval = setInterval(() => {
        tempProgress += 20;
        setProgress(tempProgress);

        //setProgress(tempProgress);
        if (tempProgress > 100) clearInterval(progressInterval);
        handleBetModalClose();
        setBetButtonDisable(false);
        setProgress(0);
      }, 5);

      setTimeout(() => {
        setLoader(false);
        toast.error(oddsResponse.msg, {
          autoClose: 2000,
          icon: false,
          closeButton: false,
          position: "top-center",
          hideProgressBar: true,
        });
      }, 300);
    }
   
    isUserLimit();
    oddsPositionResponse();
  };
  const fancyResponse = async () => {
    let fancyResponse = await Util.formSubmit(
      "client/cricket/fancy/submit",
      "POST",
      {
        token: cookies.get("client"),
        matchId: id,
        amount: amount,
        betType: pannelData.betType,
        bets: pannelData.bets,
        sessionId: pannelData.sessionId,
        run: pannelData.run,
      }
    );

    if (fancyResponse.status === "TRUE") {
      setLoader(false);
      var tempProgress = progress;
      var progressInterval = setInterval(() => {
        tempProgress += 20;
        setProgress(tempProgress);

        //console.log(tempProgress);
        if (tempProgress > 100) {
          // alert("done");

          setProgress(0);

          handleBetModalClose();

          clearInterval(progressInterval);
          setBetButtonDisable(false);
        }
      }, 15);
      setTimeout(() => {
        setLoader(false);
        toast.info("BET SUCCESSFULLY DONE", {
          autoClose: 2000,
          icon: false,
          closeButton: false,
          position: "top-center",
          hideProgressBar: true,
        });
      }, 300);
      handleFancyBetsList();
    } else {
      var tempProgress = progress;
      var progressInterval = setInterval(() => {
        tempProgress += 20;
        setProgress(tempProgress);

        //console.log(tempProgress);
        if (tempProgress > 100) {
          // alert("done");

          setProgress(0);

          handleBetModalClose();

          clearInterval(progressInterval);
          setBetButtonDisable(false);
        }
      }, 15);
      setTimeout(() => {
        setLoader(false);
        toast.error(fancyResponse.msg, {
          autoClose: 2000,
          icon: false,
          closeButton: false,
          position: "top-center",
          hideProgressBar: true,
        });
      }, 300);
    }
   
    isUserLimit();
  };

  const handleBetPlace = async () => {
    if (betbuttonDisable == true) return false;

    clearInterval(downloadTimer);
    clearTimeout(downloadTimeOut);

    setLoader(true);

    var tempProgress = 0;
    var progressInterval = setInterval(() => {
      tempProgress += 1;
      setProgress(tempProgress);

      if (tempProgress == 50) clearInterval(progressInterval);
    }, 25);

    // setProgress(30);

    setBetButtonDisable(true);
    handleBetModalClose();

    switch (pannelData.type) {
      case "ODDS":
        oddsResponse();
        break;
      case "FANCY":
        fancyResponse();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchMatch();

    initMatch();
    handleMatches((matchTimeOut = setInterval(() => handleMatches(), 500)));
    oddsPositionResponse();
    isUserLimit();
    handleFancyPlusMinus();
    handleOddsBetsList();
    handleFancyBetsList();

    let fanncyTimeOut = setInterval(() => {
      oddsPositionResponse();
      isUserLimit();
    }, 100000);

    const fetchLiveTv = async () => {
      let response = await Util.formSubmit("data/cricket/livetv", "POST", {
        token: cookies.get("client"),
        matchId: id,
      });

      if (response.status === "TRUE") {
        setTvLink(response.link);
      }
    };
    fetchLiveTv();

    return () => {
      clearInterval(matchTimeOut);
      clearInterval(fanncyTimeOut);
    };
  }, []);

  return (
    <>
      <Header />
      {/* use this loader */}
      {loader ? (
        <>
          <div className="position-fixed w-100 h-100 d-flex align-items-center justify-content-center loader">
            <img
              src="/assets/images/loader.jpeg"
              alt="Loader"
              class="flip-vertical-right"
            />
          </div>
        </>
      ) : null}
      <ToastContainer />
      <div class="position-relative">
        <table
          class="table"
          style={{ marginBottom: "-4px", marginTop: "-5px" }}
        >
          <tbody class="lgaai">
            <tr>
              <th width="50%">
                <div
                  class="toggle-tv-old lgaai"
                  onClick={toggleDiv}
                  style={{ cursor: "pointer" }}
                >
                  <p class="active text-left text-light m-0" id="tvBtn">
                    <img
                      src="/assets/images/tv.jpeg"
                      alt=""
                      style={{ width: "23px" }}
                    />
                    Tv
                  </p>
                </div>
              </th>
              <th width="50%" class="cursor-pointer">
                <div class="toggle-tv-old lgaai" onClick={handleToggle}>
                  <p class="active text-center text-light m-0" id="tvBtn">
                    {scoreText}
                  </p>
                </div>
              </th>
            </tr>
          </tbody>
        </table>

        {showDiv && (
          <div>
            <iframe
              src={tvLink.tv}
              title="Live Match"
              class="match-tv"
            ></iframe>
          </div>
        )}

       {/* added score card  */}
       <div className="bg-main text-uppercase" style={{ width: '100%', height : iframeHeight + 'px' }}>
                    <div className="row scoremain m-0" style={{color:'#FF911F'}}>
                        <div className="col-12 col-md-12">
                            
                        </div>
                        <div className="col-4 col-md-4 score-team">
                            <span className="team-name">{score.TEAM0}</span>
                            <br />
                            <span className="team-score">{score.R0}-{score.W0}</span>
                            <br />
                            <span className="team-over">{score.O0}</span>
                            <br />

                        </div>
                        <div className="col-4 col-md-4 team-message-block">

                            <span className="team-message"></span>

                        </div>
                        <div className="col-4 col-md-4 score-team-1">
                            <span className="team-name">{score.TEAM1}  </span>
                            <br />
                            <span className="team-score">{score.R1}-{score.W1}</span>
                            <br />
                            <span className="team-over">{score.O1}</span>
                            <br />
                            <span className="team-crr"> </span>

                        </div>
                    </div>
                    <div className="last-overs text-white">Last Over:
                    {
                      scoreBall6?.map(row=>{
                        return <span className="ball ">{row}</span>

                      })
                    }
                       
                    </div>  
                    <div className="row score_card m-0">

                        <div className="col-4 col-md-4">
                            <span>Name</span>
                        </div>
                        <div className="col-2 col-md-2">
                            <span>R(B)</span>
                        </div>

                        <div className="col-2 col-md-2">
                            <span>4s</span>
                        </div>
                        <div className="col-2 col-md-2">
                            <span>6s</span>
                        </div>
                        <div className="col-2 col-md-2">
                            <span>SR</span>
                        </div>
                        <div className="col-4 col-md-4 text-uppercase">
                            <span>{score.Player0}</span>
                        </div>
                        <div className="col-2 col-md-2">
                        <span>{score.RUN0}</span>
                        </div>

                        <div className="col-2 col-md-2">
                            <span>-</span>
                        </div>
                        <div className="col-2 col-md-2">
                            <span>-</span>
                        </div>
                        <div className="col-2 col-md-2">
                            <span>-</span>
                        </div>

                        <div className="col-4 col-md-4 text-uppercase">
                        <span>{score.Player1}</span>
                        </div>
                        <div className="col-2 col-md-2">
                            <span>{score.RUN1}</span>
                        </div>

                        <div className="col-2 col-md-2">
                            <span>-</span>
                        </div>
                        <div className="col-2 col-md-2">
                            <span>-</span>
                        </div>
                        <div className="col-2 col-md-2">
                            <span>-</span>
                        </div>
                    </div>

                    <div className="last-wicket-block" style={{color:'white'}}>
                        <center>
                            <div className="col-6 col-md-3">
                                <span>Partnership : -</span>
                            </div>
                        </center>


                    </div>
                  </div>

        <div class="exposure" style={{ marginTop: "-20px" }}>
          <center>
            <p>USED COINS</p>
            <p style={{ marginTop: "-5px" }}>
              <span class="text-danger">{exposure}/-</span>
            </p>
          </center>
        </div>

        {/*  */}
        <table class="match-bets-old table table-bordered">
          <thead class="lgaai">
            <tr>
              <th width="46%">
                Team
                <img
                  onClick={handleOpen}
                  src="/assets/images/info.png"
                  className="mtachImgInfo"
                  tilte="Rules (Bookmaker)"
                  alt="Rules (Bookmaker)"
                  id="BOOKMAKER"
                />
                <p class="m-0">200000</p>
              </th>
              <th
                width="22%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                class="FontTextWhite10px"
                className="vertical-middle"
              >
                Lagai
              </th>
              <th
                width="22%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                class="FontTextWhite10px"
                className="vertical-middle"
              >
                Khai
              </th>
            </tr>
          </thead>
          <tbody>
            {oddsData?.map((row, i) => {
              let k = i;
              k++;
              return (
                <tr>
                  <th class="d-flex justify-content-center align-items-center">
                    {row.Name}:{" "}
                    <b
                      className={
                        parseInt(oddsPositionAmt["Amount" + k]) < 0
                          ? "mx-2 text-danger"
                          : "mx-2 text-primary"
                      }
                    >
                      {" "}
                      {oddsPositionAmt["Amount" + k]}
                    </b>
                  </th>
                  <th
                    class="text-dark text-center font-17 teamClrBlue"
                    onClick={() => {
                      handleBetsSubmit(
                        row.Name,
                        "LAGAI",
                        row.K,
                        "ODDS",
                        row.Team,
                        ""
                      );
                    }}
                  >
                    <span>{row.K}</span>
                  </th>
                  <th
                    class="text-dark font-17 teamClrPink"
                    onClick={() => {
                      handleBetsSubmit(
                        row.Name,
                        "KHAI",
                        row.L,
                        "ODDS",
                        row.Team,
                        ""
                      );
                    }}
                  >
                    <span>{row.L}</span>
                  </th>
                </tr>
              );
            })}
            {/* // <tr>
                        //     <th class="d-flex justify-content-center align-items-center">Sydney Thunder: <b class="mx-2 text-primary">0</b></th>
                        //     <th class="text-dark text-center font-17 teamClrBlue" onClick={handleBetModalOpen}><span>0.90</span></th>
                        //     <th class="text-dark font-17 teamClrPink" onClick={handleBetModalOpen}><span>0.94</span></th>
                        // </tr> */}
          </tbody>
        </table>

        <div class="session-plus-minus mb-2 session-style">
          <p>
            SESSION P/M
            <span
              className={fancyPlusMinus > 0 ? "text-success" : "text-danger"}
            >
              {fancyPlusMinus + "/-"}
            </span>
          </p>
        </div>

        {/*  */}
        <table class="match-bets-old table table-bordered">
          <thead class="lgaai">
            <tr>
              <th width="46%">
                Session
                <img
                  onClick={handleOpen}
                  src="/assets/images/info.png"
                  className="mtachImgInfo"
                  tilte="Rules (Bookmaker)"
                  alt="Rules (Bookmaker)"
                  id="BOOKMAKER"
                />
              </th>
              <th
                width="22%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                class="FontTextWhite10px"
                className="vertical-middle"
              >
                NO
              </th>
              <th
                width="22%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                class="FontTextWhite10px"
                className="vertical-middle"
              >
                Yes
              </th>
            </tr>
          </thead>
          <tbody>
            {fancyData?.map((row) => {
              return (
                <tr>
                  <th>
                    {row.sessionName}
                    <br />
                    <small class="text-success">
                      <b>{row.maxLimit}</b>
                    </small>
                  </th>
                  <th
                    class="text-dark text-center font-17 teamClrPink"
                    onClick={() =>
                      handleBetsSubmit(
                        row.sessionName,
                        "NO",
                        row.Nrate,
                        "FANCY",
                        "",
                        row.sessionId,
                        row.NO
                      )
                    }
                  >
                    <span>{row.NO}</span>
                    <br />
                    {row.Nrate}
                  </th>
                  <th
                    class="text-dark text-center font-17 teamClrBlue"
                    onClick={() => {
                      handleBetsSubmit(
                        row.sessionName,
                        "YES",
                        row.Yrate,
                        "FANCY",
                        "",
                        row.sessionId,
                        row.YES
                      );
                    }}
                  >
                    <span>{row.YES}</span>
                    <br />
                    {row.Yrate}
                  </th>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/*  */}

        <table
          class="match-bets-old table table-bordered"
          style={{ marginTop: "-20px" }}
        >
          <thead class="no_comm_lgaai" height="25">
            <tr>
              <th
                width="46%"
                height="35"
                align="center"
                valign="middle"
                bgcolor="#FF911F"
                class="FontTextWhite10px vertical-middle"
              >
                No Comm Session
              </th>
              <th
                width="22%"
                align="center"
                valign="middle"
                bgcolor="#FF911F"
                class="FontTextWhite10px vertical-middle"
              >
                No
              </th>
              <th
                width="22%"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                class="FontTextWhite10px vertical-middle"
              >
                Yes
              </th>
            </tr>
          </thead>
          {/* <tbody>
            <td>500</td>
            <td>5</td>
            <td>8</td>
          </tbody> */}
        </table>

        {/*  */}
        <table
          width="100%"
          border="0"
          cellpadding="2"
          cellspacing="2"
          class="old-match-bets text-center"
          style={{ marginTop: "-15px" }}
        >
          <tbody>
            <tr class="text-white table-bordered">
              <th
                height="25"
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                class="FontTextWhite10px"
              >
                Sr.
              </th>
              <th
                align="right"
                valign="middle"
                bgcolor="#002D5B"
                class="FontTextWhite10px"
              >
                {" "}
                Rate
              </th>
              <th
                align="right"
                valign="middle"
                bgcolor="#002D5B"
                class="FontTextWhite10px"
              >
                Amount
              </th>
              <th
                align="center"
                valign="middle"
                bgcolor="#002D5B"
                class="FontTextWhite10px"
              >
                {" "}
                Mode
              </th>
              <th
                align="left"
                valign="middle"
                bgcolor="#002D5B"
                class="FontTextWhite10px"
              >
                Team
              </th>
            </tr>
            {oddsBetsList?.map((row, key) => {
              let count = key;
              return (
                <tr>
                  <td>{++count}</td>
                  <td>{row.bitrate}</td>
                  <td>{row.Amount}</td>
                  <td>{row.Mode}</td>
                  <td>{row.teamName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/*  */}

        <div class="table-responsive">
          <table
            width="100%"
            class="old-match-bets"
            border="0"
            cellspacing="2"
            cellpadding="2"
          >
            <thead>
              <tr class="text-white">
                <th
                  height="25"
                  align="center"
                  bgcolor="#002D5B"
                  class="FontTextWhite10px"
                >
                  Sr.
                </th>
                <th align="left" bgcolor="#002D5B" class="FontTextWhite10px">
                  Session
                </th>
                <th
                  align="right"
                  valign="middle"
                  bgcolor="#002D5B"
                  class="FontTextWhite10px"
                >
                  Rate
                </th>
                <th
                  align="right"
                  valign="middle"
                  bgcolor="#002D5B"
                  class="FontTextWhite10px"
                >
                  Amount
                </th>
                <th align="right" bgcolor="#002D5B" class="FontTextWhite10px">
                  Run
                </th>
                <th align="center" bgcolor="#002D5B" class="FontTextWhite10px">
                  Mode
                </th>
                <th align="center" bgcolor="#002D5B" class="FontTextWhite10px">
                  Dec
                </th>
              </tr>
            </thead>
            <tbody>
              {fancyBetsList?.map((row, key) => {
                let count = key;
                return (
                  <tr>
                    <td>{++count}</td>
                    <td>{row.sessionName}</td>
                    <td>{row.bitrate}</td>
                    <td>{row.Amount}</td>
                    <td>{row.run}</td>
                    <td>{row.Mode}</td>
                    <td>{row.declareRun+""}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Link class="btn btn-primary w-100 backbutton" to="/dashboard">
          Back to Main Menu
        </Link>
      </div>

      {/* Modal */}
      <Modal
        show={open}
        centered
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-title h4">
                <h2 style={{ fontWeight: "bold", fontSize: "25px" }}>
                  {" "}
                  RULES
                  <span style={{ color: "red" }}>(BOOKMAKER) </span>
                </h2>
              </div>
              <button
                type="button"
                onClick={handleClose}
                class="btn-close"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                class="matchRules"
                style={{ fontWeight: "bold", fontSize: "12px" }}
              >
                <div class="matchRules">
                  <h5> CRICKET </h5>
                  <p> 1.मैच के टाई होने पर सभी सौदे मान्य किए जायेगे,</p>
                  <p>
                    {" "}
                    2. सभी एडवांस फैंसी टॉस के कुछ देर पहले ससपेंड कर दी जाएगी ,{" "}
                  </p>
                  <p>
                    {" "}
                    3. यदि किसी तकनीकी खराबी के कारन कोई सेशन या फैंसी ID से हट
                    जाती है और दुबारा ID पर नहीं आती है तो उस सेशन पर किए गए सभी
                    सौदे मान्य किए जायेगे,{" "}
                  </p>
                  <p>
                    4. यदि किसी सेशन या फैंसी के रेट गलत चल जाते है तो गलत रेट
                    पर किए गए सौदे हटा दिए जायेगे,
                  </p>
                  <p>
                    5. स्कोर बोर्ड और टीवी थर्ड पार्टी सोर्स है अगर स्कोर स्लो
                    और गलत चलता है तो इस मे कम्पनी की कोई ज़िम्मेदारी नहीं है
                    कृपया सौदा अपने हिसाब से करे,
                  </p>
                  <p class="redP">
                    6. यदि कोई क्लाइंट ग्राउंड कमेंर्टी से एक ही IP से BOOT से
                    VPN से एक है टाइम मे एक से सौदे करता है तो करता है तो केवल
                    जीत वाले सौदे हटा दिए जाएंगे , उदहारण:-(
                    <span class="redSpan">
                      6 ओवर सेशन मै 44 YES और 48 NOT करता है और रन 43 बनते है तो
                      48 वाले सभी सौदे हटा दिए जा सकते है,
                    </span>
                    )
                  </p>
                  <p>
                    7. पेनल्टी के रन बर्तमान मे चल रहे किसी भी सेशन पर मान्य
                    नहीं किए जाते है,
                  </p>
                  <p>
                    8. यदि बर्तमान मे सेशन चल रहे हो और टीम (DLS) या किसी भी
                    कारन से जीत जाती है तो ओवर वाले सेशन जो कम्पलीट नहीं हुए है
                    वो रद्द कर दिया जायेगा और एक्स्ट्रा सेशन (
                    <span class="redSpan">
                      विकेट पार्टनरशिप ,प्लेयर के रन,एक्सटरा फैंसी) सभी पास किआ
                      जायेगा,
                    </span>
                  </p>
                  <p>
                    9.प्लेयर के 35 रन पर रिटायर्ड हर्ट या इंजर्ड होने पर प्लेयर
                    के रन डिक्लेअर 35 ही किये जायेंगे ,
                  </p>
                  <p>
                    {" "}
                    10. एडवांस सेशन और फैंसी केवल 1ST इनिंग के लिए ही VALID होती
                    है,{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* betting modal */}
      <Modal
        show={BetModalopen}
        centered
        className="bet-modal"
        onClose={handleBetModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div class="">
          <div class="modal-content">
            <a class="close-modal" onClick={handleBetModalClose}>
              Cancel
            </a>
            <div class="modal-body">
              <div class="container">
                <div class="bet-strip row">
                  <div class="col-6">
                    <label>Team</label>
                    <p>{pannelData.title}</p>
                  </div>
                  <div class="col-3">
                    <label>Rate</label>
                    <p>
                      {pannelData.type === "ODDS"
                        ? pannelData.bets
                        : pannelData.bets}
                    </p>
                  </div>
                  <div class="col-3">
                    <label>Mode</label>
                    <p>{pannelData.betType}</p>
                  </div>
                </div>
                <div class="my-3 row">
                  <div class="bet-price-btns col-lg-12 col-md-12 col-sm-12 col-12">
                    <button
                      type="button"
                      class="bet-price-btn btn btn-primary"
                      onClick={() => handleButtonClick("100")}
                    >
                      100
                    </button>
                    <button
                      type="button"
                      class="bet-price-btn btn btn-primary"
                      onClick={() => handleButtonClick("500")}
                    >
                      500
                    </button>
                    <button
                      type="button"
                      class="bet-price-btn btn btn-primary"
                      onClick={() => handleButtonClick("1000")}
                    >
                      1000
                    </button>
                    <button
                      type="button"
                      class="bet-price-btn btn btn-primary"
                      onClick={() => handleButtonClick("2000")}
                    >
                      2000
                    </button>
                    <button
                      type="button"
                      class="bet-price-btn btn btn-primary"
                      onClick={() => handleButtonClick("5000")}
                    >
                      5000
                    </button>
                    <button
                      type="button"
                      class="bet-price-btn btn btn-primary"
                      onClick={() => handleButtonClick("10000")}
                    >
                      10000
                    </button>
                    <button
                      type="button"
                      class="bet-price-btn btn btn-primary"
                      onClick={() => handleButtonClick("20000")}
                    >
                      20000
                    </button>
                    <button
                      type="button"
                      class="bet-price-btn btn btn-primary"
                      onClick={() => handleButtonClick("50000")}
                    >
                      50000
                    </button>
                    <button
                      type="button"
                      class="bet-price-btn btn btn-primary"
                      onClick={() => handleButtonClick("100000")}
                    >
                      100000
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3 input-group">
                      <input
                        placeholder="Amount"
                        max="200000"
                        value={amount}
                        onChange={handleInputChange}
                        type="Number"
                        class="form-control"
                      />
                      <span
                        class="bg-danger text-white input-group-text"
                        id="basic-addon3"
                      >
                        <p>{timemer}</p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-0 modal-footer">
              <button
                type="button"
                class="w-100 m-0 btn btn-primary"
                onClick={
                  amount === ""
                    ? ""
                    : () => {
                        handleBetPlace();
                      }
                }
              >
                Placebet
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
