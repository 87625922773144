import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Util from "../../../libs/util";
import moment from "moment";
import { useNavigate } from "react-router-dom";
export const MatkaRoundCards = () => {


  const [matkaList, setMatkaList] = useState([]);

  const cookies = new Cookies();
  const navigate = new useNavigate();
  const fetchMatka = async () => {
    let response = await Util.formSubmit("client/games/list", "POST", {
      token: cookies.get("client"),
    });
    if (response.status === "TRUE") {
      let arr = response.msg;

      setMatkaList(arr);
    }

   
  };

  const onClickNav = (status, id, ref,endtime,gameName) => {
    if (!status) {
      alert("Timeout here waiting for result...");
    }else{
      navigate('/matka/gameplay/'+id+'/'+ref,{
        state:{
          endtime:endtime,
          gameName:gameName
        }
      });
    }
  };

  useEffect(() => {
    fetchMatka();
   let marketTimeout =  setInterval(() => {
      fetchMatka();
    }, 5000);

    return()=>{
      clearInterval(marketTimeout);
    }
    
  }, []);

  return (
    <>
      <div className="row">
        {matkaList.map((row) => {
          let crTime = moment();
          let enTime = moment(row.betime);
          let valueResult = crTime.isBefore(enTime) ? "--" : "XX";
          return (
            <div
              className="col-md-3 col-sm-12"
              onClick={() => {
                onClickNav(crTime.isBefore(enTime), row.sno, row.ref_id,row.betime,row.matka_name);
              }}
            >
              <div className="matka-round ">
                <div className="details">
                  <p className="heading">{row.matka_name}</p>
                  <p className="subline">
                    {moment(row.bstime).format("hh:mm A")} To{" "}
                    {moment(row.betime).format("hh:mm A")} Result at{" "}
                    {moment(row.result_time).format("hh:mm A")}
                  </p>
                </div>
                <div>
                  <p className="number">
                    {row.isresult == "0" ? valueResult : row.result}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
