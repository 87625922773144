import React from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";

export const MatkaPlayMulti = () => {
  return (
    <>
      <Header />
      <div className="position-relative">
        <Link className="btn btn-primary w-100 backbutton" to="/matka">
          Back to Main Menu
        </Link>

        <div className="container-fluid">
          <div className="row mt-4"></div>
        </div>
      </div>
    </>
  );
};
