import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Link } from "react-router-dom";
import { IntroWatch } from "./components/others/IntroWatch";
import { ResultCrousel } from "./components/others/ResultCrousel";
import { MatkaRoundCards } from "./components/others/MatkaRoundCards";
import Util from "../libs/util";
import Cookies from "universal-cookie";

function Dashboard() {
  const cookies = new Cookies();

  const [resultsData, setResultsData] = useState([]);

  const results = async () => {
    let response = await Util.formSubmit("client/games/list-result", "POST", {
      token: cookies.get("client"),
    });

    if (response.status == "TRUE") {
      // console.log(response?.msg);
      setResultsData([...response?.msg]);
    }
  };

  useEffect(() => {

   results();

   let resultTimer = setInterval(()=>{
    results();
   },5000)

    return () => {

      clearInterval(resultTimer);

    };
  }, []);

  return (
    <>
      <Header />
      <div className="dashboard">
        <IntroWatch />
        <ResultCrousel result={resultsData} />
        <MatkaRoundCards />
      </div>
    </>
  );
}
export default Dashboard;
