import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserLimit } from "../../../appRedux/actions/Auth";
import Cookies from "universal-cookie";
import Util from "../../../libs/util";

export const Harruf = ({ gameRef, gameId }) => {
  const [jodis, setJodis] = useState([]);
  const [jodisL, setJodisL] = useState([]);

  const userLimit = useSelector((state) => state.userLimit);
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const [pointsA, setPointsA] = useState(0);

  const jodimaker = () => {
    let tempjodi = [];

    for (let j = 0; j <= 9; j++) {
      tempjodi.push(j.toString());
    }

    setJodis(tempjodi);
  };

  const addJodi = (event, id, type) => {
    let temp_jodi = [...jodisL];
    const isObjectPresent = temp_jodi.findIndex(
      (o) => o.jodi === id && o.type == type
    );

    if (isObjectPresent >= 0) {
      if (event.target.value == "") temp_jodi.splice(isObjectPresent, 1);
      else temp_jodi[isObjectPresent].points = event.target.value;
    } else {
      temp_jodi.push({ type: type, jodi: id, points: event.target.value });
    }

    // if((userLimit-pointsA)<=0){
    //   temp_jodi[isObjectPresent].points = 0;
    //   alert("No more points remain...");

    // }

    //  console.log(temp_jodi);
    setJodisL([...temp_jodi]);

    const sumPoints = temp_jodi.reduce((acc, o) => acc + parseInt(o.points), 0);
    //console.log(temp_jodi);
    setPointsA(sumPoints);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (userLimit - pointsA <= 0) {
      alert("Limit Exceed, cannot save !!!");
      return false;
    }

    let errorJodi = jodisL.filter((row) => parseInt(row.points) > 5000);

    if (errorJodi.length > 0) {
      alert("single bet more than 5000 not allowed !!!");
      return false;
    }

    if (jodisL.length > 0) {
      let response = await Util.formSubmit("client/games/save", "POST", {
        svm: jodisL,
        gameId: gameId,
        refs: gameRef,
        boardType: "HARUF",
        token: cookies.get("client"),
      });

      if (response.status == "TRUE") {
        await isUserLimit();
        setJodisL([]);
        setPointsA(0);

        alert(response.msg);
      } else {
        alert(response.msg);
      }
    }
  };

  const isUserLimit = async () => {
    let userinfo = await Util.formSubmit("client/user/limit", "POST", {
      token: cookies.get("client"),
    });
    if (userinfo.status === "TRUE") {
      // this.props.setUserLimit(userinfo.limit)
      dispatch(setUserLimit(userinfo.limit));
      // setExposure(userinfo.exposure);
    }
  };

  useEffect(() => {
    jodimaker();

    return () => {};
  }, []);

  return (
    <>
      <div className="pointer">
        <div>
          <p className="header">Points Remaining</p>
          <p className="point-value">{userLimit - pointsA}</p>
        </div>
        <div>
          <p className="header">Points Added</p>
          <p className="point-value">{pointsA}</p>
        </div>
      </div>
      <div className="haruf">
        <div className="main-content">
          <h2>Andar Haruf</h2>
          {jodis.map((row) => {
            let obj = jodisL?.find(
              (row1) => row1.jodi == row && row1.type == "andar"
            );
            return (
              <div key={row} className="divj">
                <h1>{row}</h1>
                <input
                  onChange={(event) => {
                    addJodi(event, row, "andar");
                  }}
                  type="number"
                  value={typeof obj != "undefined" ? obj?.points : ""}
                />
              </div>
            );
          })}

          <h2>Bahar Haruf</h2>
          {jodis.map((row) => {
            let obj = jodisL?.find(
              (row1) => row1.jodi == row && row1.type == "bahar"
            );
            return (
              <div key={row} className="divj">
                <h1>{row}</h1>
                <input
                  onChange={(event) => {
                    addJodi(event, row, "bahar");
                  }}
                  type="number"
                  value={typeof obj != "undefined" ? obj?.points : ""}
                />
              </div>
            );
          })}
        </div>
        <div className="footer">
          <button className="btn btn-primary" onClick={onSubmit}>
            Play
          </button>
        </div>
      </div>
    </>
  );
};
