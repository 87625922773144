import React from "react";

function Footer() {
    return (
        <>
          <div className="row">
                    <center>
                    <div className="col col-img">
                        <img height="65" src="assets/images/indian_casino.gif" className="default-icon mt-4 card_3d" alt="" />
                    </div>
                    <div className="col col-img">
                    <img height="25" src="assets/images/ssl.png"  alt="" className="default-icon mt-4" />
                    <img height="15" src="assets/images/betfair.png"  alt="" className="default-icon mt-4" />
                    <img height="20" src="assets/images/18.png"  alt="" className="default-icon mt-4" />
                    <img height="15" src="assets/images/android.png"  alt="" className="default-icon mt-4" />
                    </div>
                    <div className="col col-img">
                    <img height="15" src="assets/images/l1.png"  alt="" className="default-icon mt-4" />
                    <img height="15" src="assets/images/l2.png"  alt="" className="default-icon mt-4" />
                    <img height="15" src="assets/images/l3.png"  alt="" className="default-icon mt-4" />
                    </div>
                    </center>
                 </div>
        </>
    )
}

export default Footer