import React, { useState } from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import Util from "../libs/util";
import Cookies from "universal-cookie";

export default function Password() {

    const cookies = new Cookies();
    const [fields, setFields] = useState({ oldPassword: "", newPassword: "", conPassword: "" });
    
    const stateHandler = (e) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
    }
    const onSubmit = async (e) => {

        e.preventDefault();
        if(fields.newPassword!=fields.conPassword)
        {
            alert("password and confirm password not match");
            return;
        }

        let response = await Util.formSubmit("client/user/change_password", "POST", {
            "token": cookies.get("client"),
            "oldPassword": fields.oldPassword,
            "password": fields.newPassword
        });
        if (response.status === "TRUE") {
            alert("password has been changed");
        } else {
            alert("password has not been changed");
        }

    }
    return (
        <>
         <Header />
            <div class="position-relative">
                <Link class="btn btn-primary w-100 backbutton" to='/dashboard'>Back to Main Menu</Link>
                <div class="toast-container position-absolute top-0 start-50 translate-middle-x p-3 position-fixed mt-5"></div>
                <div class="mt-4 container">
                    <div class="mt-4 row">
                        <div class="m-auto col-lg-4 col-md-6 col-sm-7 col-12">
                            <div class="p-4 card">
                                <h3 class="card-title text-center text-bold">CHANGE PASSWORD</h3>
                                <form class="">
                                    <div class="my-2">
                                        <div class="mb-3 input-group"><span class="bg-dark-opacity input-group-text">
                                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3.433 17.325 3.079 19.8a1 1 0 0 0 1.131 1.131l2.475-.354C7.06 20.524 8 18 8 18s.472.405.665.466c.412.13.813-.274.948-.684L10 16.01s.577.292.786.335c.266.055.524-.109.707-.293a.988.988 0 0 0 .241-.391L12 14.01s.675.187.906.214c.263.03.519-.104.707-.293l1.138-1.137a5.502 5.502 0 0 0 5.581-1.338 5.507 5.507 0 0 0 0-7.778 5.507 5.507 0 0 0-7.778 0 5.5 5.5 0 0 0-1.338 5.581l-7.501 7.5a.994.994 0 0 0-.282.566zM18.504 5.506a2.919 2.919 0 0 1 0 4.122l-4.122-4.122a2.919 2.919 0 0 1 4.122 0z"></path></svg>
                                        </span>
                                        <input placeholder="Current Password" onChange={stateHandler} name="oldPassword" type="password" class="form-control" /></div>
                                    </div>
                                    <div class="my-2">
                                        <div class="mb-3 input-group"><span class="bg-dark-opacity input-group-text">
                                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3.433 17.325 3.079 19.8a1 1 0 0 0 1.131 1.131l2.475-.354C7.06 20.524 8 18 8 18s.472.405.665.466c.412.13.813-.274.948-.684L10 16.01s.577.292.786.335c.266.055.524-.109.707-.293a.988.988 0 0 0 .241-.391L12 14.01s.675.187.906.214c.263.03.519-.104.707-.293l1.138-1.137a5.502 5.502 0 0 0 5.581-1.338 5.507 5.507 0 0 0 0-7.778 5.507 5.507 0 0 0-7.778 0 5.5 5.5 0 0 0-1.338 5.581l-7.501 7.5a.994.994 0 0 0-.282.566zM18.504 5.506a2.919 2.919 0 0 1 0 4.122l-4.122-4.122a2.919 2.919 0 0 1 4.122 0z"></path></svg>
                                        </span>
                                        <input placeholder="New Password" onChange={stateHandler} name="newPassword" type="password" class="form-control" /></div>
                                    </div>
                                    <div class="my-2">
                                        <div class="mb-3 input-group"><span class="bg-dark-opacity input-group-text">
                                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3.433 17.325 3.079 19.8a1 1 0 0 0 1.131 1.131l2.475-.354C7.06 20.524 8 18 8 18s.472.405.665.466c.412.13.813-.274.948-.684L10 16.01s.577.292.786.335c.266.055.524-.109.707-.293a.988.988 0 0 0 .241-.391L12 14.01s.675.187.906.214c.263.03.519-.104.707-.293l1.138-1.137a5.502 5.502 0 0 0 5.581-1.338 5.507 5.507 0 0 0 0-7.778 5.507 5.507 0 0 0-7.778 0 5.5 5.5 0 0 0-1.338 5.581l-7.501 7.5a.994.994 0 0 0-.282.566zM18.504 5.506a2.919 2.919 0 0 1 0 4.122l-4.122-4.122a2.919 2.919 0 0 1 4.122 0z"></path></svg>
                                        </span>
                                        <input placeholder="Confirm Password" onChange={stateHandler} name="conPassword" type="password" class="form-control" /></div>
                                    </div>
                                    <button type="button" onClick={onSubmit} class="w-100 mt-4 btn btn-success" style={{    backgroundColor: 'rgb(0, 45, 91)'}}>CHANGE PASSWORD</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Link class="casino-back-btn backbutton" to="/dashboard">Back to Main Menu</Link>
            </div>

        </>
    )
}
