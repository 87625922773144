import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import Inplay from "./pages/Inplay";
import Casino from "./pages/Casino";
import FreeGames from "./pages/FreeGames";
import Statement from "./pages/Statement";
import Profile from "./pages/Profile";
import Ledger from "./pages/Ledger";
import Password from "./pages/Password";
import Cricket from "./pages/games/Cricket";
import Bubble from "./pages/games/Bubble";
import Rummy from "./pages/games/Rummy";
import Croudrun from "./pages/games/Croudrun";
import PlayMatch from "./pages/PlayMatch";
import Rules from "./pages/Rules";
import CasinoDetail from "./pages/CasinoDetail";
import Matka from "./pages/Matka";
import MatkaPlay from "./pages/MatkaPlay";
import ViewStatement from "./pages/ViewStatement";
import { MatkaResults } from "./pages/MatkaResults";
import { MatkaPlayMulti } from "./pages/MatkaPlayMulti";
import { MatkaGamePlay } from "./pages/MatkaGamePlay";
import { ShowMatkaBets } from "./pages/ShowMatkaBets";

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/in-play" element={<Inplay />} />
          <Route path="/upcoming" element={<Inplay />} />

          <Route path="/statement" element={<Statement />} />
          <Route path="/FreeGames" element={<FreeGames />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/ledger" element={<Ledger />} />
          <Route path="/password" element={<Password />} />
          <Route path="/play-match/:id" element={<PlayMatch />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/matka" element={<Matka />} />
          <Route path="/matka-result" element={<MatkaResults />} />
          <Route path="/play-matka/:matkatype/:id" element={<MatkaPlay />} />
          <Route
            path="/play-matka-multi/:refid/:id"
            element={<MatkaPlayMulti />}
          />
          <Route path="/matka/gameplay/:id/:ref" element={<MatkaGamePlay />} />
          <Route path="/matka/show-bets" element={<ShowMatkaBets />} />

          <Route path="/view-statement/:id" element={<ViewStatement />} />

          {/* Games */}
          <Route path="/cricket" element={<Cricket />} />
          <Route path="/bubble" element={<Bubble />} />
          <Route path="/rummy" element={<Rummy />} />
          <Route path="/croudrun" element={<Croudrun />} />

          {/* Casino */}
          <Route path="/casino" element={<Casino />} />
          <Route path="/twenty-twenty-teenpatti" element={<CasinoDetail />} />
          <Route path="/dragon-tiger" element={<CasinoDetail />} />
          {/* <Route path="/casino/dragon-tiger" element={<CasinoDetail />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);
