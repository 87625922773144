import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Util from "../libs/util";

export default function Matka() {
  const cookies = new Cookies();

  const [gameList, setGameList] = useState([]);

  const getGames = async () => {
    try {
      let response = await Util.formSubmit("/client/games/list", "POST", {
        token: cookies.get("client"),
      });

      if (response.status === "TRUE") {
        setGameList(response.msg);
      }
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      await getGames();
    })();
  }, []);

  return (
    <>
      <Header />
      <div className="position-relative">
        <Link className="btn btn-primary w-100 backbutton" to="/dashboard">
          Back to Main Menu
        </Link>

        <div className="container-fluid pd2">
          <div className="row mt-2">
            <div className="col-lg-12">
              <Link
                className="btn btn-primary w-100 resultbutton"
                to="/matka-result"
              >
                {" "}
                Bets / Result
              </Link>
            </div>
          </div>
          <div className="row mt-4">
            {gameList.map((row) => {
              return (
                <div className="col-lg-12 card-matka">
                  <div className="header-matka">
                    <h2>
                      {row.matkaName}
                      <div className="blink"></div>
                    </h2>
                  </div>
                  <div className="matka-date">
                    <h2>{row.datem}</h2>
                  </div>
                  <div className="button-matka">
                    <Link
                      to={"/play-matka/jodi/" + row.sno}
                      className="jodi-button"
                    >
                      JODI
                    </Link>
                    <Link
                      to={"/play-matka/bahar/" + row.sno}
                      className="dhai-button"
                    >
                      DHAI
                    </Link>
                    <Link
                      to={"/play-matka/andar/" + row.sno}
                      className="haruf-button"
                    >
                      HARUF
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Link className="btn btn-primary backbutton w-100" to="/dashboard">
          Back to Main Menu
        </Link>
      </div>
    </>
  );
}
