import React from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Link } from "react-router-dom";


export default function FreeGames() {
    return (
        <>
            <Header />
            <div class="position-relative">
                <Link class="btn btn-primary backbutton w-100" to="/dashboard">Back to Main Menu</Link>
                <div class="mt-4 row">
                    <div class="text-center dashboard-icons col-sm-3 col-6"><Link to="/cricket">
                        <img src="/assets/images/freegames.jpg" alt="" class="card_3d" />
                        <p>Cricket</p>
                    </Link></div>
                    <div class="text-center dashboard-icons position-relative col-sm-3 col-6">
                        <Link to="/croudrun">
                            <img class="card_3d" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNfougH7w9UtmimFTmOXKqlZq_QI-roYb-JQ&usqp=CAU" alt="" />
                            <p>Croud Run 3D</p>
                        </Link></div>
                    <div class="text-center dashboard-icons position-relative col-sm-3 col-6"><Link to="/bubble">
                        <img class="card_3d" src="https://static.keygames.com/9/78739/44869/672x448/bubble-shooter.webp" alt="" />
                        <p>Bubble</p>
                    </Link></div>
                    <div class="text-center dashboard-icons position-relative col-sm-3 col-6"><Link to="/rummy">
                        <img class="card_3d" src="https://cdn.playerzpot.com/images/rummy.png" alt="" />
                        <p>Rummy</p>
                    </Link>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <Footer />
            </div>

        </>
    )
}
