import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Link, useParams } from "react-router-dom";
import Util from "../libs/util";
import Cookies from "universal-cookie";

export default function ViewStatement() {
  const [details, setDetails] = useState([]);
  const [odds, setOdds] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [team, setTeam] = useState({});
  const [team1, setTeam1] = useState({});
  const [plusminus, setPlusMinus] = useState({});
  const cookies = new Cookies();
  useEffect(() => {
    (async () => {
      let response = await Util.formSubmit("client/cricket/bets/list", "POST", {
        token: cookies.get("client"),
      });
      if (response.status === "TRUE") {
        setDetails(response.data);
        setOdds(response.data.odds);
        setFancy(response.data.fancy);
        setTeam(response.data.teams);
        setTeam1(response.data.teams2);
        setPlusMinus(response.data.plusMinus);
      }
    })();
  }, []);

  return (
    <>
      <Header />
      <div class="position-relative">
        <Link className="btn btn-primary backbutton w-100" to="/statement">
          Back to Statement
        </Link>
        <div class="table-responsive">
          <table class="statement-tables table table-bordered">
            <thead>
              <tr>
                <th colspan="7" class="bg-theme-dark text-center">
                  Match Won By: {details.wonBy}
                </th>
              </tr>
              <tr class="bg-dark text-white">
                <th>Rate</th>
                <th>Amount</th>
                <th>Mode</th>
                <th>Team</th>
                <th>{team[1]}</th>
                <th>{team[2]}</th>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {odds.map((row) => {
                return (
                  <tr>
                    <td>{row.bitrate}</td>
                    <td>{row.Amount}</td>
                    <td>{row.Mode}</td>
                    <td>{team1[row.Team_id]}</td>
                    <td class="text-primary">{row.Amount1}</td>
                    <td class="text-danger">{row.Amount2}</td>
                    <td class="text-primary"></td>
                  </tr>
                );
              })}

              <td
                colspan="7"
                className={
                  plusminus.ODDS > 0
                    ? "text-center text-success"
                    : "text-center text-danger"
                }
                style={{ fontSize: "16px" }}
              >
                YOU {plusminus.ODDS > 0 ? "WON" : "LOST"} {plusminus.ODDS} /-
                coins.
              </td>
            </tbody>
          </table>
        </div>
        <table class="table-responsive statement-tables table table-bordered">
          <thead>
            <tr>
              <th colspan="7" class="text-center text-white bg-theme-dark">
                Session Bets
              </th>
            </tr>
            <tr class="text-white bg-dark">
              <th>Session</th>
              <th>Rate</th>
              <th>Amount</th>
              <th>Runs</th>
              <th>Mode</th>
              <th>Dec</th>
              <th>P/L</th>
            </tr>
          </thead>
          <tbody>
            {fancy.map((row) => {
              return (
                <tr>
                  <td>
                    {row.Name}
                    <span class="text-danger"></span>{" "}
                  </td>
                  <td>{row.bitrate}</td>
                  <td>{row.Amount}</td>
                  <td>{row.run}</td>
                  <td>{row.Mode}</td>
                  <td>{row.declareRun}</td>
                  <td class="text-primary">{}</td>
                </tr>
              );
            })}

            <td
              colspan="7"
              className={
                plusminus.FANCY > 0
                  ? "text-center text-primary"
                  : "text-center text-danger"
              }
              style={{ fontSize: "16px" }}
            >
              YOU {plusminus.FANCY > 0 ? "WON" : "LOST"} {plusminus.FANCY}/-
              coins.
            </td>
          </tbody>
        </table>
        <table class="table-responsive statement-tables table">
          <thead>
            <tr>
              <th class="bg-theme-dark text-center text-white">
                Match Session Plus Minus
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={
                  plusminus.final > 0
                    ? "text-center text-primary"
                    : "text-center text-danger"
                }
              >
                YOU {plusminus.final > 0 ? "WON" : "LOST"} {plusminus.final}
                /-coins
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table-responsive statement-tables table">
          <thead>
            <tr>
              <th class="bg-theme-dark text-center text-white">
                My Commission
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center text-primary">0/- coins</td>
            </tr>
          </tbody>
        </table>
        <table class="table-responsive statement-tables table">
          <thead>
            <tr>
              <th class="bg-theme-dark text-white text-center">
                Amount After Comm.
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={
                  plusminus.final > 0
                    ? "text-center text-primary"
                    : "text-center text-danger"
                }
              >
                {plusminus.final > 0 ? "WON" : "LOST"} {plusminus.final}/-
                coins.
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table-responsive statement-tables table">
          <thead>
            <tr>
              <th class="bg-theme-dark text-white text-center">
                Mob. App. Charges
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center text-danger">0/- coins</td>
            </tr>
          </tbody>
        </table>
        <table class="table-responsive statement-tables table">
          <thead>
            <tr>
              <th class="text-center bg-theme-dark text-whit">
                Net Plus Minus
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={
                  plusminus.final > 0
                    ? "text-center text-primary"
                    : "text-center text-danger"
                }
              >
                {plusminus.final > 0 ? "WON" : "LOST"} {plusminus.final}/- coins
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
