import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import Util from "../../libs/util";
import { setSession, setUserLimit } from "../../appRedux/actions/Auth";

function Header() {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLimit = useSelector((state) => state.userLimit);
  const user = useSelector((state) => state.session.user);

  useEffect(() => {
    isUser();
    setInterval(function () {
      isUser();
    }, 15000);
    isUserLimit();
  }, []);

  const isUser = async () => {
    let userinfo = await Util.formSubmit("client/user", "POST", {
      token: cookies.get("client"),
    });
    if (userinfo.status === "TRUE") {
      dispatch(setSession({ user: userinfo.data }));
    } else {
      cookies.remove("client");
      navigate("/");
    }
  };

  const isUserLimit = async () => {
    let userinfo = await Util.formSubmit("client/user/limit", "POST", {
      token: cookies.get("client"),
    });
    if (userinfo.status === "TRUE") {
      console.log(userinfo.limit);
      dispatch(setUserLimit(userinfo.limit));
    }
  };

  const handleLogout = async () => {
    cookies.remove("client", { path: "/" });
    navigate("/");
  };

  const list = (anchor) => (
    <Box
      className=""
      id="offcanvasNavbar-expand-navbar"
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div variant="white" class="offcanvas-header">
        <a href="/dashboard" class="logo">
          <img src="assets/images/logo2.png" alt="" />
        </a>
        <button type="button" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <div class="justify-content-end flex-grow-1 pe-3 navbar-nav">
          <Link to="/dashboard" class="active nav-link">
            HOME
          </Link>
          <Link to="/matka/show-bets" class="nav-link">
            BET HISTORY
          </Link>

          {/* <Link to="/statement" class="nav-link">
            STATEMENT
          </Link> */}
          <Link to="/ledger" class="nav-link">
            LEDGER
          </Link>
          <Link to="/password" class="nav-link">
            PASSWORD
          </Link>

          {/* <Link to="/rules" class="nav-link">
            RULES
          </Link> */}

          <Link to="/" onClick={handleLogout} class="nav-link">
            LOGOUT
          </Link>
        </div>
      </div>
    </Box>
  );

  return (
    <>
      <nav class="bg-theme-dark p-0 navbar navbar-expand-navbar navbar-dark">
        <div class="container-fluid">
          <div>
            <React.Fragment key="right">
              <Button
                className="navbar-toggler collapsed"
                variant="outlined"
                onClick={toggleDrawer("right", true)}
              >
                <MenuIcon />
              </Button>

              <Drawer
                anchor="right"
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
              >
                {list("right")}
              </Drawer>
            </React.Fragment>
            <Link to="/dashboard" className="logo">
              <img src="/assets/images/logo2.png" alt="" />
            </Link>
          </div>
          <div class="profile-details text-center">
            <p class="m-0">
              {user?.username} ({user?.userid})
            </p>
            <p class="m-0">
              <img
                width="16px"
                src="/assets/images/wallet.png"
                alt="Wallet"
                style={{ marginRight: "3px" }}
              />
              chips: {userLimit}
              <button class="btn btn-transparent p-0 m-0">
                <img src="/assets/images/refresh.png" width="20" alt="" />
              </button>
            </p>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Header;
