import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Jodi } from "./components/gameplay/Jodi";
import { Manual } from "./components/gameplay/Manual";
import { Harruf } from "./components/gameplay/Harruf";
import { CopyPaste } from "./components/gameplay/CopyPaste";
import { Crossing } from "./components/gameplay/Crossing";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Countdown from "react-countdown";
import Cookies from "universal-cookie";
import Util from "../libs/util";
export const MatkaGamePlay = () => {
  const [tabBar, setTabBar] = useState(0);

  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const cookies = new Cookies();

  if (state == null) {
    navigate("/dashboard");
  }

  const barChange = (status) => {
    setTabBar(status);
  };
  const tabRender = (status) => {
    switch (status) {
      case 0:
        return <Jodi gameRef={params.id} gameId={params.ref} />;
      case 1:
        return <Manual gameRef={params.id} gameId={params.ref} />;
      case 2:
        return <Harruf gameRef={params.id} gameId={params.ref} />;
      case 3:
        return <Crossing gameRef={params.id} gameId={params.ref} />;
      default:
        return <Jodi gameRef={params.id} gameId={params.ref} />;
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      navigate("/dashboard");
      return;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const initMatka = async () => {
    let result = await Util.formSubmit("client/games/init", "POST", {
      token: cookies.get("client"),
      id: params.id,
      ref: params.ref,
    });
  };

  useEffect(() => {
    initMatka();
    return () => {};
  }, []);

  return (
    <>
      <Header />
      <div className="gameplay">
        <div className="top-bar-container">
          <ul className="top-bar">
            <li
              className={tabBar == 0 && "active"}
              onClick={() => {
                barChange(0);
              }}
            >
              Jodi
            </li>
            <li
              className={tabBar == 1 && "active"}
              onClick={() => {
                barChange(1);
              }}
            >
              Manual
            </li>
            <li
              className={tabBar == 2 && "active"}
              onClick={() => {
                barChange(2);
              }}
            >
              Haruf
            </li>
            <li
              className={tabBar == 3 && "active"}
              onClick={() => {
                barChange(3);
              }}
            >
              Crossing
            </li>
            {/* <li
              className={tabBar == 4 && "active"}
              onClick={() => {
                barChange(4);
              }}
            >
              Copy Paste
            </li> */}
          </ul>
        </div>
        <div className="counter-game">
          {state?.gameName} game Ends in{" "}
          <Countdown date={state?.endtime} renderer={renderer} />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">{tabRender(tabBar)}</div>
          </div>
        </div>
      </div>
    </>
  );
};
