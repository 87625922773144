import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";

export const ResultCrousel = ({result}) => {

  //console.log(result);
  return (
    <>
      <Carousel
        className="result-slider"
        showArrows={true}
        showStatus={false}
        showIndicators={false}
      >

      
{
        result && result.map(row=>{
          return <div className="silders">
          <p className="header">{row.matka_name}</p>
          <p className="content">Result - {row.result}</p>
          <p className="content">{moment(row.result_time).format("DD-MM-YYYY hh:mm A")}</p>
        </div>
        })
       
        }
      </Carousel>
    </>
  );
};
