export default class Util {
  static async formSubmit(path, method, data) {
    // const baseUrl = "/api/2.0/index.php/";
    //const baseUrl = "//161.97.143.183/matkapro/api/index.php/";
    const baseUrl = "https://api.1ex.biz/index.php/";

    let response = await fetch(baseUrl + path, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.json();
  }
}
