import React, { useState } from 'react';
import Header from "./components/Header";
import Modal from 'react-bootstrap/Modal'

export default function CasinoDetail() {
    const [BetModalopen, setBetModalOpen] = React.useState(false);
    const [LastBetModalopen, setLastBetModalOpen] = React.useState(false);
    const [rules, setrules] = React.useState(false);
    const handleBetModalOpen = () => setBetModalOpen(true);
    const handleBetModalClose = () => setBetModalOpen(false);
    const handleLastBetModalOpen = () => setLastBetModalOpen(true);
    const handleLastBetModalClose = () => setLastBetModalOpen(false);
    const handlerulesOpen = () => setrules(true);
    const handlerulesClose = () => setrules(false);

    const [activeTab, setActiveTab] = useState('Game');
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };


    return (
        <>
            <Header />
            <div class="d-flex align-items-center justify-content-between" style={{ background: 'rgb(0, 45, 91)', color: 'rgb(255, 255, 255)' }}>
                <div class="d-flex">
                    <button
                        type="button"
                        className={`btn tab-button casino-tabs btnclr ${activeTab === 'Game' ? 'btnclr casino-tabs text-white active' : 'bg-white text-white'}`}
                        onClick={() => handleTabClick('Game')} >
                        Game
                    </button>
                    <button
                        type="button"
                        className={`btn tab-button casino-tabs btnclr ${activeTab === 'Placed' ? 'btnclr casino-tabs text-white active' : 'bg-white text-white'}`}
                        onClick={() => handleTabClick('Placed')}>
                        Placed Bets
                    </button>
                    <button type="button" onClick={handlerulesOpen} class="tab-button casino-tabs btn btn-primary btnclr">
                        RULES</button>
                </div>
                <div>233012213028</div>
            </div>

            {activeTab === 'Game' && (
                <div class="position-relative">
                    <div>
                        <iframe src="https://alpha-n.qnsports.live/route/anm.php?id=3030" title="Live Casino" width="100%" height="320px"></iframe>
                    </div>
                    <table class="mb-0 table">
                        <thead>
                            <tr style={{ backgroundColor: 'rgb(0, 45, 91)', textAlign: 'right', color: 'white' }}>
                                <th colspan="2" width="80">Back</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="d-flex justify-content-between">Player A</th>
                                <th class="casino_lgaai position-relative" onClick={handleBetModalOpen}>1.98</th>
                            </tr>
                            <tr>
                                <th class="d-flex justify-content-between">Player B</th>
                                <th class="casino_lgaai position-relative">1.54<span class="locked-session">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z">
                                        </path>
                                    </svg>
                                </span></th>
                            </tr>

                        </tbody>

                    </table>

                    {/*  */}
                    <div class="last-results mb-1 h-70">
                        <div class="result-headings">
                            <h4>Last Result</h4>
                            <h4>View Result</h4>
                        </div>
                        <ul class="results mt-2 mb-0">
                            <li><span onClick={handleLastBetModalOpen} className="clrA">A</span></li>
                            <li><span onClick={handleLastBetModalOpen} className="clrB">B</span></li>
                            <li><span onClick={handleLastBetModalOpen} className="clrB">B</span></li>
                            <li><span onClick={handleLastBetModalOpen} className="clrB">B</span></li>
                            <li><span onClick={handleLastBetModalOpen} className="clrA">A</span></li>
                            <li><span onClick={handleLastBetModalOpen} className="clrA">A</span></li>
                            <li><span onClick={handleLastBetModalOpen} className="clrB">B</span></li>
                            <li><span onClick={handleLastBetModalOpen} className="clrA">A</span></li>
                            <li><span onClick={handleLastBetModalOpen} className="clrA">A</span></li>
                            <li><span onClick={handleLastBetModalOpen} className="clrB">B</span></li>
                        </ul>
                    </div>

                    {/*  */}

                    <div class="last-results mb-5">
                        <div class="result-headings">
                            <h4>Placed Bets</h4>
                        </div>
                        <ul class="casino-bets-list mt-3">
                            <li>
                                <h4>Player A - (11.233012204654)</h4>
                                <div class="d-flex justify-content-between bet">
                                    <div>
                                        <h4>Profit</h4>
                                        <p>98</p>
                                    </div>
                                    <div>
                                        <h4>Loss</h4>
                                        <p>-100</p>
                                    </div>
                                    <div>
                                        <h4>Amount</h4>
                                        <p>100</p>
                                    </div>
                                    <div>
                                        <h4>Decision</h4>
                                        <p></p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    {/* Modalbet */}
                    <Modal
                        show={BetModalopen}
                        centered
                        className='casino-bet-modal'
                        onClose={handleBetModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div class="modal-content">
                            <div class="modal-header">
                                <div class="modal-title h4" id="contained-modal-title-vcenter">Place Bet on Player A</div>
                                <button type="button" onClick={handleBetModalClose} class="btn-close" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-sm-6 col-6">
                                            <div><label class="form-label">Rate</label>
                                                <input type="Number" readonly="" class="form-control" value="1.98" /></div>
                                        </div>
                                        <div class="col-sm-6 col-6">
                                            <div><label class="form-label">Amount</label>
                                                <input max="20000" min="100" type="Number" class="form-control" value="" /></div>
                                        </div>
                                    </div>
                                    <div class="mt-3 row">
                                        <div class="casino-bet-amounts col-lg-12 col-md-12 col-sm-12 col-12">
                                            <button type="button" class="bet-price-btn btn btn-primary">100</button>
                                            <button type="button" class="bet-price-btn btn btn-primary">200</button>
                                            <button type="button" class="bet-price-btn btn btn-primary">500</button>
                                            <button type="button" class="bet-price-btn btn btn-primary">1000</button>
                                            <button type="button" class="bet-price-btn btn btn-primary">5000</button>
                                            <button type="button" class="bet-price-btn btn btn-primary">10000</button>
                                            <button type="button" class="bet-price-btn btn btn-primary">20000</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-light" onClick={handleBetModalClose} >Cancel</button>
                                <button type="button" class="btn btn-success" onClick={handleBetModalClose} >Place Bet</button>
                                <button type="button" class="btn btn-danger">1</button>
                            </div>
                        </div>

                    </Modal>

                    {/* Modalbet */}
                    <Modal
                        show={LastBetModalopen}
                        centered
                        className='casino-bet-modal small-modal'
                        onClose={handleLastBetModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description" >
                        <div class="modal-content">
                            <div class="modal-header">20-20 Teenpatti Result
                                <button type="button" class="btn-close" aria-label="Close" onClick={handleLastBetModalClose}>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="text-right"><strong>Round ID: </strong>233012205232</p>
                                <div class="bet-result">
                                    <div class="row">
                                        <div class="text-center col-6">
                                            <h4>Player
                                                <img src="/assets/images/cup.png" alt=""
                                                    className="cupstyle" /></h4>
                                            <div class="bet-cards teen_patti_t20 false">
                                                <span><img src="/assets/images/j.png" /></span>
                                                <span><img src="/assets/images/j.png" /></span>
                                                <span><img src="/assets/images/j.png" /></span>
                                            </div>
                                        </div>
                                        <div class="text-center col-6">
                                            <h4>Player B </h4>
                                            <div class="bet-cards teen_patti_t20">
                                                <span><img src="/assets/images/j.png" /></span>
                                                <span><img src="/assets/images/j.png" /></span>
                                                <span><img src="/assets/images/j.png" /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal>


                </div>
            )}
            {activeTab === 'Placed' && (
                <div class="position-relative">
                    <div class="last-results mb-5">
                      
                        <ul class="casino-bets-list mt-3">
                            <li>
                                <h4>Player A - (11.233012204654)</h4>
                                <div class="d-flex justify-content-between bet">
                                    <div>
                                        <h4>Profit</h4>
                                        <p>98</p>
                                    </div>
                                    <div>
                                        <h4>Loss</h4>
                                        <p>-100</p>
                                    </div>
                                    <div>
                                        <h4>Amount</h4>
                                        <p>100</p>
                                    </div>
                                    <div>
                                        <h4>Decision</h4>
                                        <p></p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            )}
                 <Modal
                        show={rules}
                        centered
                        className='casino-bet-modal small-modal'
                        onClose={handlerulesClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description" >
                        <div class="modal-content">
                            <div class="modal-header">20-20 Teenpatti Result
                                <button type="button" class="btn-close" aria-label="Close" onClick={handlerulesClose}>
                                </button>
                            </div>
                            <div class="modal-body">
                               <img src='assets/images/teenpatti-rules.jpg' width="100%" alt='' />
                            </div>
                        </div>

                    </Modal>

        </>
    )
}