import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Cricket() {
    return (
        <>
            <Header />
            <div class="position-relative">
                <iframe width="100%" height="450px" marginheight="0" frameborder="0" border="0" scrolling="auto" src="https://doodlecricket.github.io/#/">

                </iframe>
            </div>
            <div className="container-fluid mb-3">
            <Footer />
            </div>
        </>
    )
}
