import React, { useState } from "react";
import { useFormInputValidation } from "react-form-input-validation";
import { useDispatch, useSelector } from "react-redux";
import Util from "../../../libs/util";
import { setUserLimit } from "../../../appRedux/actions/Auth";
import Cookies from "universal-cookie";
export const Crossing = ({ gameRef, gameId }) => {
  const userLimit = useSelector((state) => state.userLimit);
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const [pointsA, setPointsA] = useState(0);

  const [fields, errors, form] = useFormInputValidation(
    {
      number1: "",
      number2: "",
      points: "",
    },
    {
      number1: "required|numeric",
      number2: "required|numeric",
      points: "required|numeric",
    }
  );

  const [crossings, setCrossings] = useState([]);

  const addCross = async (event) => {
    const isValid = await form.validate(event);
    console.log(isValid);
    if (isValid) {
      let num1array = fields.number1.split("");
      let num2array = fields.number2.split("");
      let finaljodi1 = [];
      let finaljodi2 = [];
      let finaljodi_pre = [];
      let finaljodi = [];

      num1array.map((row1) => {
        num2array.map((row2) => {
          finaljodi1.push(row1.toString() + row2.toString());
        });
      });

      num2array.map((row1) => {
        num1array.map((row2) => {
          finaljodi2.push(row1.toString() + row2.toString());
        });
      });

      finaljodi_pre = Array.from(new Set(finaljodi1.concat(finaljodi2)));

      let pointerA = 0;

      finaljodi_pre.map((row) => {
        finaljodi.push({ jodi: row, points: fields.points, type: "jodi" });

        pointerA += parseInt(fields.points);
      });

      setCrossings(finaljodi);
      setPointsA(pointerA);
    }
  };

  const deleteBit = (index) => {
    let jodis = [...crossings];

    console.log(index);
    jodis.splice(index, 1);

    setCrossings(jodis);
    const sumPoints = jodis.reduce((acc, o) => acc + parseInt(o.points), 0);

    setPointsA(sumPoints);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (userLimit - pointsA <= 0) {
      alert("Limit Exceed, cannot save !!!");
      return false;
    }

    let errorJodi = crossings.filter((row) => parseInt(row.points) > 5000);

    if (errorJodi.length > 0) {
      alert("single bet more than 5000 not allowed !!!");
      return false;
    }

    if (crossings.length > 0) {
      let response = await Util.formSubmit("client/games/save", "POST", {
        svm: crossings,
        gameId: gameId,
        refs: gameRef,
        boardType: "CROSSING",
        token: cookies.get("client"),
      });

      if (response.status == "TRUE") {
        await isUserLimit();
        setCrossings([]);
        setPointsA(0);

        alert(response.msg);
      } else {
        alert(response.msg);
      }
    }
  };

  const isUserLimit = async () => {
    let userinfo = await Util.formSubmit("client/user/limit", "POST", {
      token: cookies.get("client"),
    });
    if (userinfo.status === "TRUE") {
      // this.props.setUserLimit(userinfo.limit)
      dispatch(setUserLimit(userinfo.limit));
      // setExposure(userinfo.exposure);
    }
  };

  return (
    <>
      <div className="pointer">
        <div>
          <p className="header">Points Remaining</p>
          <p className="point-value">{userLimit - pointsA}</p>
        </div>
        <div>
          <p className="header">Points Added</p>
          <p className="point-value">{pointsA}</p>
        </div>
      </div>

      <div className="crossing">
        <div className="form-meter">
          <form noValidate autoComplete="off" onSubmit={addCross}>
            <div className="form-gr">
              <div className="group">
                <label>Number</label>
                <input
                  placeholder="Number"
                  name="number1"
                  onBlur={form.handleBlurEvent}
                  onChange={form.handleChangeEvent}
                  value={fields.number1}
                />
                <label className="error">
                  {errors.number1 ? errors.number1 : ""}
                </label>
              </div>
              <div className="group">
                <label>Number</label>
                <input
                  placeholder="Number"
                  name="number2"
                  onBlur={form.handleBlurEvent}
                  onChange={form.handleChangeEvent}
                  value={fields.number2}
                />
                <label className="error">
                  {errors.number2 ? errors.number2 : ""}
                </label>
              </div>
            </div>
            <div className="form-gr">
              <div className="group">
                <label>Point</label>
                <input
                  placeholder="Point"
                  name="points"
                  onBlur={form.handleBlurEvent}
                  onChange={form.handleChangeEvent}
                  value={fields.points}
                />
                <label className="error">
                  {errors.points ? errors.points : ""}
                </label>
              </div>
            </div>
            <div className="form-gr">
              <div className="group">
                <button className="btn btn-primary" type="submit">
                  +ADD
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="form-meter">
          <div className="row header">
            <div className="column">Type</div>
            <div className="column">Number</div>
            <div className="column">Point</div>
            <div className="column">Delete</div>
          </div>
          <div className="mid-container">
            {crossings.map((row, i) => {
              return (
                <div className="row" key={i}>
                  <div className="column">Crossing</div>
                  <div className="column">{row.jodi}</div>
                  <div className="column">{row.points}</div>
                  <div
                    className="column"
                    onClick={() => {
                      deleteBit(i);
                    }}
                  >
                    <img src="/assets/images/delete.png" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="footer">
          <button className="btn btn-primary" onClick={onSubmit}>
            Play
          </button>
        </div>
      </div>
    </>
  );
};
